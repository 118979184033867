// Colors
$papaya:             #f8ccc2;
$light_papaya:       #f6b6a0;
$dark_papaya:        #92523b;

$yellow:             #fcca75;
$light_yellow:       #fddfac;
$dark_yellow:        #997B48;

$light_orange:       #f4a28c;
$orange:             #f18860;
$dark_orange:        #ca6647;

$lime:               #cecf25;
$light_lime:         #e2e27c;
$dark_lime:          #7e7e18;

$olive:              #528352;
$light_olive:        #97b597;
$dark_olive:         #335033;

$light_red:          #e97996;
$red:                #c95671;
$dark_red:           #a1465b;

$purple:             #4d3f92;
$light_purple:       #5d47cf;

$white:              #f7f5ea;

$green:              #284e5b;
$medium_light_green: #415f6d;
$light_green:        #7e959d;
$dark_green:         #1a3038;

$lightest_gray:      #f2f2f2;
$lighter_gray:       #d3d3d3;
$light_gray:         #bbbbbb;
$gray:               #888888;
$dark_gray:          #3D4D54;

$facebook_blue:      #1877F2;
$discord_purple:     #5865F2;
$roblox_black:       #111216;

$input_border:       #dee2e6;

// Fonts
$poiret: 'Poiret One', sans-serif;
$fredoka: 'Fredoka One', sans-serif;
$montserrat: 'Montserrat', sans-serif;
$roboto: 'Roboto', sans-serif;
$dancing_script: 'Dancing Script', cursive;
$lato: 'Lato', sans-serif;
$bangers: 'Bangers', cursive;
