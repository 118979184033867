.__password-reset {
  width: 400px;
  max-width: 95%;
  text-align: center;
  margin: 0 auto;

  .reset-form {
    max-width: 260px;
    margin: 0 auto;
  }

  .btn {
    width: 100%;
    display: block;
  }
}