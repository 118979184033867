@import '../../palette';

.__permissions-dropdown {

  a {
    color: $white;
    
    &:hover {
      color: $light_yellow;
    }
  }

  &.show {
    .dropdown-toggle {
      border-color: $gray;
    }
  }

  .btn-success.dropdown-toggle:active {
    background-color: transparent;
  }

  .btn-link.focus, .btn-link:focus {
    text-decoration: none;
  }

  .btn.focus, .btn:focus {
    box-shadow: none;
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    border-color: transparent;
    background-color: transparent;
    color: white;
    border-radius: 32px;

    &:hover {
      border-color: $gray;
      text-decoration: none;
    }

    &:focus {
      background-color: transparent;
    }

    > svg {
      fill: white;
      margin-inline-end: 8px;

      &.hidden {
        fill: rgba(white, 0.5);
      }
    }

    .label {
      margin-inline-end: 4px;
      min-width: 60px;
    }
  }
  
  .dropdown-menu {
    background-color: $dark_green;
    border: 1px solid rgba(white, 0.8);
    box-shadow: 0 0 2px 2px rgba(black, 0.2);
    color: white;
    padding: 2px;

    .dropdown-item {

      &:hover {
        background-color: rgba(white, 0.05);
      }

      &:active, &.active {
        background-color: $green;
      }
    }

    .item-value {
      display: flex;
      font-size: 16px;
      margin-top: 8px;
      margin-bottom: 2px;
      font-weight: bold;
      align-items: center;

      > svg {
        fill: white;
        margin-inline-end: 8px;
      }
    }

    .item-description {
      color: $light_green;
      font-size: 85%;
      margin-bottom: 8px;
    }
  }
}
