@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__survey {
  width: 800px;
  max-width: 96%;
  margin: 96px auto 0;

  h1.header {
    font-family: $fredoka;
    margin: 0px auto 40px;
    text-transform: uppercase;
    font-size: 2.2rem;
    letter-spacing: 1px;
    text-align: center;
  }

  .survey {
    display: flex;
    justify-content: center;
  }
  .coupon{
    margin-top: 20px;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 100px;
  }
}