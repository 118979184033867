@import '../palette';

.__modal {

  @media (min-width: 576px) {
    .modal-sm {
      max-width: 360px;
    }
  }

  .modal-header {
    color: $orange;
    font-weight: bold;
    font-size: 1.2rem;

    .close {
      color: $orange;
      opacity: 1;

      &:hover {
        color: $light_orange;
      }
    }
  }

  .modal-content {
    padding: 8px;
    border-radius: 16px;
  }

  .modal-footer {

    .btn {
      color: white;
      font-weight: bold;
      border-radius: 32px;
      padding: 8px 20px;
      border: none;
      font-size: 1.05rem;
    }
  
    .btn-secondary {
      background-color: $orange;
  
      &:hover:not(:disabled) {
        background-color: $light_orange;
      }
  
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($orange, 0.5);
      }
      
      &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
        background-color: $light_orange;
        box-shadow: 0 0 0 0.2rem rgba($orange, 0.5);
      }
    }
  
    .btn-primary {
      background-color: $purple;
  
      &:hover:not(:disabled) {
        background-color: $light_purple;
      }
  
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($purple, 0.5);
      }
      
      &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
        background-color: $light_purple;
        box-shadow: 0 0 0 0.2rem rgba($purple, 0.5);
      }
    }
  }

  &-loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: white;
    border-radius: 16px;

    &-inner {
      text-align: center;
      color: $dark_gray;

      > svg {
        margin-bottom: 1rem;
      }
    }
  }
}
