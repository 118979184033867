@import '../../palette';
@import 'node_modules/include-media/dist/_include-media.scss';

.__settings {
  margin: 0 auto;

  .user-role {
    width: 800px;
    max-width: 96%;
    display: flex;
    margin: 20px auto;
    padding: 16px;
    background-color: $dark_green;
    border-radius: 8px;

    @include media("<=720px") {
      flex-direction: column;
    }

    .icon {
      width: 80px;
      margin-inline-end: 16px;

      @include media("<=720px") {
        margin: 0 auto;
        margin-bottom: 16px;
      }
    }

    .text {
      flex: 1;

      @include media("<=720px") {
        font-size: 0.85em;
      }

      h3 {
        font-size: 1.2rem;
        font-weight: bold;

        @include media("<=720px") {
          text-align: center;
        }
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    span[role=button] {
      color: $orange;
    }
  }

  .overflow-container {
    // width: 100%;
    overflow: hidden;
  }
  .premium-member {
    &-container {
      position: relative;
      max-width: 96%;
      width: 800px;
      margin: 0 auto;

      .right-red-circle {
        position: absolute;
        width: 200px;
        height: 200px;
        background-color: $red;
        border-radius: 100%;
        top: 6%;
        left: 90%;
        z-index: 2;
      }

      .lime-inverted-circle {
        position: absolute;
        width: 90px;
        height: 90px;
        top: 46%;
        left: 96.5%;
        z-index: 1;
        background-color: $lime;
        display: flex;
        align-items: center;
        justify-content: center;

        .inner-circle {
          background-color: $green;
          border-radius: 100%;
          width: 60px;
          height: 60px;
          // position: absolute;
          // top: 50%;
          // left: 50%;
        }
      }

      .circle-small {
        position: absolute;
        width: 34px;
        height: 34px;
        left: 102%;
        border-radius: 100%;

        &.orange-circle {
          background-color: $orange;
          top: 72%;
        }

        &.green-circle {
          background-color: $olive;
          top: 82%;
        }
      }
    }

    background-color: $dark_green;
    width: 800px;
    max-width: 96%;
    margin: 0 auto;
    padding: 20px 80px;
    position: relative;
    border-radius: 96px;
    box-shadow: -20px 20px 0 1px $red;
    padding-bottom: 80px;
    margin-bottom: 140px;
    min-height: 460px;
    z-index: 10;

    @include media("<=720px") {
      padding: 32px 12px;
      border-radius: 64px;
      min-height: 400px;
    }

    h3 {
      color: $lime;
      font-size: 42px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;

      @include media("<=720px") {
        font-size: 28px;
      }
    }

    .avatar {
      position: absolute;
      left: -8%;
      top: 33%;
      width: 240px;
      height: 240px;

      @include media("<=720px") {
        width: 120px;
        height: 120px;
        left: 4px;
        top: 40%;
      }
    }

    .information {
      margin-top: 80px;
      padding-left: 140px;

      @include media("<=720px") {
        padding-left: 120px;
        margin-top: 60px;
      }
    }

    .days-left {
      color: $orange;
      font-style: italic;
      font-size: 24px;
      font-weight: bold;
    }

    p.details {
      font-size: 18px;
      margin-bottom: 20px;
    }
    
    button {
      background-color: $lime;
      color: $purple;
      border-radius: 20px;
      padding: 2px 8px;
      border: none;
      font-family: inherit;
      font-weight: bold;

      &:hover {
        background-color: $light_lime;
      }
    }
    
    .bottom-red-semicircle {
      position: absolute;
      width: 45px;
      height: 90px;
      background-color: $red;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      top: 101%;
    }

    .bottom-lime-semicircle {
      background-color: $lime;
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
      position: absolute;
      top: -15px;
      width: 45px;
      height: 90px;
      
      &-container {
        position: absolute;
        top: 104.5%;
        left: 16.5%;
        width: 45px;
        height: 90px;
        overflow: hidden;
      }
    }
  }

  .settings-selection {
    width: 700px;
    max-width: 96%;
    margin: 0 auto;
  }

  .entry {
    margin-bottom: 30px;

    .field {
      font-size: 18px;
      margin-bottom: 12px;
    }
    .input{
      display: flex;
      align-items: center;
      position: relative;
      input {
        background-color: transparent;
        color: $white;
        border-color: rgba($white, 0.5);
        border-radius: 32px;
        padding-right: 44px;
        padding-left: 22px;
        height: 50px;
      }
      
      svg {
        height: 20px;
        width: 20px;
        position: absolute;
        right: 16px;
        opacity: 0;
      }

      &:hover {
        svg {
          opacity: 1;
          transition: opacity 0.5s linear;
        }
      }
    }

    .status-container {
      display: flex;
      justify-content: flex-end;

      .status {
        margin-top: 5px;
        margin-right: 6px;
        font-weight: 500;

        &.success{
          color: $lime;
        }

        &.failure{
          color: $papaya;
        }
        .verify-email-prompt {
          cursor: pointer;
        }
      }
    }
    .language-input{
      border-radius: 32px;
      height: 50px;
    }
  }

  .change-password {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    button {
      background-color: $red;
      color: $white;
      border: 1px solid $white;
      height: 50px;
      border-radius: 32px;
      font-weight: 500;
      font-size: 18px;

      &:hover:not(:disabled), &:active:not(:disabled) {
        background-color: $light_red;
        border: 1px solid $white;
      }
    }
  }

  .header{
    background-color: $green;
    z-index: 2;
    font-family: $fredoka;
    margin: 0px auto 40px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    letter-spacing: 1px;

    > svg {
      fill: $orange;
      margin-right: 8px;
    }
  }

  .cancel-options {
    margin: 12px auto;
    text-align: center;
    display: flex;
    gap: 6px;
    justify-content: center;

    > div[role=button] {
      color: $light_red;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .bottom-links {
    display: flex;
    gap: 8px;
    margin: 20px auto;
    justify-content: center;

    a:hover {
      color: $papaya;
    }
  }
}

.__cancelled-modal {

  .modal-dialog {
    max-width: 420px;
  }

  .content {
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 12px 4px;

    @include media("<=720px") {
      flex-direction: column;
    }
  }

  div {
    flex: 2;
  }

  .sad-icon {
    max-width: 120px;
    
    path {
      fill: $purple;
    }
  }
}
