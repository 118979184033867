@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__transactions {
  max-width: 900px;
  margin: 0 auto;
  padding: 12px;

  .header {
    background-color: $green;
    z-index: 2;
    font-family: $fredoka;
    margin: 0px auto 40px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    letter-spacing: 1px;
    text-align: center;

    > svg {
      fill: $orange;
      margin-right: 8px;

      @include media("<=720px") {
        display: none;
      }
    }
  }

  .options {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    @include media("<=720px") {
      flex-direction: column;
      gap: 8px;
    }

    .my-balance {
      font-size: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      font-weight: 500;

      @include media("<=720px") {
        justify-content: space-between;
      }
  
      svg {
        margin-left: 15px;
        margin-right: 9px;
      }
    }
  
    .filters {
      display: flex;
  
      .cutoff_date-input {
        margin-right: 20px;
      }
  
      .form-control {
        background-color: $green;
        border: 1px solid $white;
        color: $white;
        
        @include media(">720px") {
          width: 150px;
        }
  
        &:disabled {
          color: $light_gray;
          border-color: $light_gray;
        }
      }
  }

  }

  .empty-transactions {
    border: 3px dashed $dark_red;
    padding: 60px 16px;
    border-radius: 4px;
    text-align: center;
    line-height: 2.4rem;
    font-size: 1.1em;
  }

  .initial-load {
    text-align: center;
    margin: 48px auto;

    .__infinite-loader {
      margin-bottom: 20px;
    }
  }

  .transaction-view {
    width: 100%;
    margin: 32px auto 60px;

    thead {

      @include media("<=720px") {
        visibility: collapse;
      }

      .column-created_at {
        width: 15%;

        @include media("<=720px") {
          display: none;
        }
      }

      .column-coin_transaction_reason {
        width: 70%;
        text-align: left;
      }

      .column-coin_amount {
        width: 15%;
        text-align: left;
      }

      th {
        padding: 6px 0;
        text-align: center;

        background-color: $orange;

        &:first-child {
          border-top-left-radius: 32px;
          border-bottom-left-radius: 32px;
        }
        &:last-child {
          border-top-right-radius: 32px;
          border-bottom-right-radius: 32px;  
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px 0;

          @include media("<=720px") {
            padding: 6px 0;

            &.total, &.created_at {
              display: none;
            }
          }

          @include media(">720px") {
            &.total-mobile {
              visibility: hidden;
            }
          }
        }

        .centered {
          text-align: center;
        }

        .created_at {
          vertical-align: top;
        }

        .title {
          max-width: 360px;
          overflow: hidden;
          text-overflow: ellipsis;

          @include media("<=720px") {
            max-width: 200px;
          }

          a {
            color: $light_yellow;

            &:hover {
              color: $yellow;
            }
          }

          .reason {
            display: block;
            line-height: 1rem;
          }

          .details {
            font-size: 0.9rem;
            color: $light_gray;
            white-space: nowrap;
          }
        }

        .currency {
          display: flex;
          align-items: center;
          font-variant-numeric: tabular-nums;

          svg {
            margin-right: 9px;
          }
        }

        .date-mobile {
          font-size: 0.8em;
          color: $light_gray;

          @include media(">720px") {
            display: none;
          }
        }
      }

      .transaction-total {
        border-top: 1px solid $white;
        font-weight: 600;
        font-size: 20px;

        .total {
          text-align: center;
        }
      }
    }
  }
}
