@import '../../palette';

.__share-track-modal {

  .link-copied {
    font-size: 90%;
    color: $olive;
    text-align: center;
    opacity: 1;
    transition: 0.3s all;
    margin-bottom: 12px;

    &.hidden {
      visibility: hidden;
      opacity: 0;
      transform: translateY(5px);
      transition: 0.3s all;
    }
  }
  
  
  input {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
  }

  .copy-link {

    margin-top: 24px;
    margin-bottom: 12px;

    .btn-primary {
      background-color: $purple;
      color: white;
      font-weight: bold;
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
      padding-right: 16px;
      border: none;
  
      &:hover:not(:disabled) {
        background-color: $light_purple;
      }
  
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($purple, 0.5);
      }
      
      &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
        background-color: $light_purple;
        box-shadow: 0 0 0 0.2rem rgba($purple, 0.5);
      }
    }
  }
}