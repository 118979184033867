@import '../../palette';

.__infinite-loader {
  fill: $light_gray;
  animation: __infinite-loader-animation 1s infinite linear;
}

@keyframes __infinite-loader-animation {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}
