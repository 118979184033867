@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__song-library {

  overflow-x: hidden;
  min-height: 100vh;  
  
  &-container {
    width: 980px;
    max-width: 98%;
    margin: 24px auto 96px;

    @include media(">1366px") {
      width: 1160px;
    }
  }

  .loading {
    text-align: center;
    margin: 20px 0;

    pre {
      color: white;
    }

    .__infinite-loader {
      margin-bottom: 20px;
    }
  }

  .header {

     margin-bottom: 20px;

     @include media("<=720px") {
      margin-right: 8px;
    }

    .title {
      background-color: $green;
      z-index: 2;
      font-family: $fredoka;
      margin: 0px auto 12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.2rem;
      letter-spacing: 1px;

      @include media("<=720px") {
        display: none;
      }

      > svg {
        fill: $lime;
        margin-right: 8px;
      }  
    }

    .sort-filter {
      display: flex;
      justify-content: flex-end;
      gap: 4px;
      align-items: center;

      .btn {
        background-color: rgba($white, 0.1);
        color: $white;
        border-color: transparent;
        font-weight: 500;
        min-width: 80px;

        &:hover {
          background-color: rgba($white, 0.2);
          transition: 0.15s;
        }
      }
    }

    .desktop-filter {
      @include media("<=720px") {
        display: none;
      }

      .btn {
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.85em;
        transition: 0ms;

        &.active, &:active {
          background-color: $orange;
          transition: 0ms;
        }

        &.favorites {
          > svg {
            margin-inline-end: 4px;

            > path {
              fill: $lime;
            }
          }
        }

        &.purchased {
          > svg {
            margin-inline-end: 4px;
          }
        }

        &.create-sequence {
          background-color: $dark_red;
          font-weight: bold;
        }
      }
    }

    .mobile-filter {
      @include media(">720px") {
        display: none;
      }

      .sort {
        > .btn {
          border-radius: 0;
          border-bottom-right-radius: 24px;
          border-top-right-radius: 24px;

          &:after {
            vertical-align: middle;
            margin-left: 0.4em;
          }
        }

        .dropdown-menu {
          padding-left: 8px;
          padding-right: 8px;
          border-radius: 8px;
        }

        .dropdown-item.active, .dropdown-item:active {
          background-color: $orange;
          font-weight: 500;
          border-radius: 8px;
        }
      }

      .filter {
        border-radius: 24px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .showing-filters {
    text-align: right;
    font-style: italic;
    margin-bottom: 24px;

    @include media("<=720px") {
      text-align: center;
    }

    > span[role=button] {
      color: $yellow;
      text-decoration: underline;
      
      &:hover {
        color: $light_yellow;
      }
    }
  }

  // used to detect if the mobile library is visible:
  .mobile-library {
    @include media("<=720px") {
      display: none;
    }
  }

  .track-view {
    width: 100%;
    
    @include media("<=720px") {
      margin: 0 auto;
      padding: 8px;
    }

    thead {
      @include media("<=720px") {
        visibility: collapse;
      }

      th {
        padding: 6px 0;
        text-align: center;

        background-color: $orange;

        &:first-child {
          border-top-left-radius: 32px;
          border-bottom-left-radius: 32px;
        }
        &:last-child {
          border-top-right-radius: 32px;
          border-bottom-right-radius: 32px;  
        }

        &.column-title {
          .sortable {
            justify-content: start;

            .title {
              margin-inline-start: 0;
            }
          }
        }
        
        &.column {

          &-updated_at {
            min-width: 80px;
          }

          &-favorite {
            min-width: 80px;
          }

          &-options {
            min-width: 112px;
          }  

          @include media(">1366px") {
            &-style {
              min-width: 140px;
            }
  
            &-updated_at {
              min-width: 120px;
            }

            &-duration {
              min-width: 80px;
            }
  
            &-favorite {
              min-width: 80px;
            }
          }
        }

        @include media("<=720px") {
          &.column-playback, &.column-style, &.column-modified, &.column-updated_at, &.column-duration {
            display: none;
          }

          &.column-mood {
            .sortable {
              display: none;
            }
          }

          &.column-style, &.column-updated_at, &.column-favorite, &.column-options, &.column-duration {
            min-width: unset;
          }
        }

        .sortable {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          @include media("<=720px") {
            display: none;
          }

          &:focus-within {
            outline: 2px $white auto;
          }
          
          .title {
            margin-inline-start: 12px;
          }

          svg.title {
            height: 20px;
            width: 20px;
            
            .__duration-icon__st0 {
              stroke: $white;
            }
          }

          .sort-icon {
            width: 6px;
            height: 6px;
            margin-inline-start: 8px;
            visibility: hidden;

            &.visible {
              visibility: visible;
            }

            > path {
              fill: $white;
            }

            &.desc {
              transform: rotate(180deg);
            }
          }

          &:hover {
            color: $light_yellow;

            > svg > path {
              fill: $light_yellow;
            }

            svg.title > .__duration-icon__st0 {
              stroke: $light_yellow;
            }
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $gray;
        // ! Safari doesn't get the relative (column hover will overflow), so height cannot be 100%:
        // position: relative;
        user-select: none;

        @include media("<=720px") {
          border-bottom: none;
        }

        .column-hover {
          position: absolute;
          //! NOTE this had to be magic numbered to 44 because Safari height = 100% uses full screen for the column,
          //! breaking layout. Make sure to check Safari hover on columns (desktop view) if this value changes:
          left: 0;
          right: 0;
          // left: -1000px;
          // right: -1000px;
          // height: 100%;
          height: 44px;
          pointer-events: none;
          visibility: hidden;
          background-color: rgba(white, 0.07);

          @include media("<=720px") {
            // ! Same safari bug as above, needs to be magic numbered:
            height: 50px;
            // ! Safari overflows (even when top node is x-hidden):
            left: 0px;
            right: 0px;
          }

          &.highlighted {
            visibility: visible;
            background-color: rgba(white, 0.15);
          }
        }

        &:hover {
          
          .column-hover {
            visibility: visible;
          }

          .favorite {
            .inactive {
              visibility: visible;
            }
          }

          .purchase {
            visibility: visible;
          }

          .options-menu {
            visibility: visible;
          }

          .playback-container {
            .track-number {
              visibility: hidden;
            }

            .control-icons {
              visibility: visible;
            }

            .equalizer {
              visibility: hidden;
            }
    
            .controls .control-icons.song-active {
              visibility: visible;
            }
          }
        }

        .centered {
          text-align: center;
        }

        .cell-style {
          font-size: 0.9rem;
        }

        .title-container {
          width: 200px;

          @include media(">1366px") {
            width: 320px;
          }

          @include media("<=720px") {
            width: unset;
            max-width: 56vw;
          }

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 18px;

            &.active {
              color: $light_orange;
            }
          }

          .subtitle {
            font-style: italic;
            color: $light_gray;
            font-size: 0.8rem;

            &.desktop {
              @include media("<=720px") {
                display: none;
              }
            }

            &.mobile {
              @include media(">720px") {
                display: none;
              }  
            }
          }
        }

        td {

          @include media("<=720px") {
            padding: 4px 0;

            &.cell-playback, &.cell-styles, &.cell-modified, &.cell-creator {
              display: none;
            }
          }

          .generate-mobile {
            display: none;
            width: 28px;
            height: 28px;
            margin-left: 4px;

            @include media("<=720px") {
              display: initial;
            }
          }

          .regenerating-mobile {
            @include media("<=720px") {
              display: none;
            }
          }

          .options-menu {

            visibility: hidden;

            &.highlighted {
              visibility: visible;
            }

            @include media("<=720px") {
              visibility: visible;  
            }

            .btn {
              font-size: 2rem;
              line-height: 2rem;
              letter-spacing: 4px;
              color: $white;
              text-decoration: none;
              padding: 2px;
              margin: 2px;
              text-align: center;

              @include media("<=720px") {
                font-size: 1rem;
                font-weight: bold;
              }

              &:after {
                display: none;
              }
            }

            .dropdown-menu {
              background-color: $dark_green;
              border: 1px solid rgba(white, 0.5);
              box-shadow: 0 0 2px 2px rgba(black, 0.1);
              padding: 2px;
          
              .dropdown-item {

                color: $white;
          
                &:hover {
                  background-color: $green;
                }
          
                &:active, &.active {
                  background-color: $green;
                }
              }
          
              .item-value {
                display: flex;
                font-size: 16px;
                margin-top: 8px;
                margin-bottom: 2px;
                font-weight: bold;
                align-items: center;
          
                > svg {
                  fill: white;
                  margin-inline-end: 8px;
                }
              }
          
              .item-description {
                color: $light_green;
                font-size: 85%;
                margin-bottom: 8px;
              }
            }
          }

          .favorite {
            all: unset;
            cursor: pointer;
            padding: 0 4px;
            border-radius: 2px;

            &:focus-visible {
              box-shadow: 0 0 0 2px $light_lime;
            }

            > svg {
              width: 20px;
              height: 20px;

              > path {
                fill: $lime;
              }
            }

            .inactive {
              visibility: hidden;

              @include media("<=720px") {
                visibility: visible;
              }

              > path {
                fill: none;
                stroke: $white;
              }
            }

            .active {
              animation: animation-toggle-favorite 0.5s;
            }

            &:hover {
              > svg > path {
                stroke: $lime;
                transition: 0.3s;
              }
            }
          }

          .purchase {
            all: unset;
            cursor: pointer;
            padding: 0 4px;
            border-radius: 2px;
            visibility: hidden;

            @include media("<=720px") {
              visibility: visible;
            }

            > svg {
              width: 28px;
              height: 28px;

              > path {
                fill: $white;
              }
            }

            &:hover {
              > svg > path {
                fill: $yellow;
                transition: 0.3s;
              }
            }
          }

          .purchased {
            all: unset;
            cursor: pointer;
            padding: 0 4px;
            border-radius: 2px;

            > svg {
              width: 28px;
              height: 28px;
            }
          }

          .purchase, .purchased {
            transform: scale(0.9);
          }

        }
        
        .skeleton-cell {
          background: rgba($light-green, 0.5);
          display: inline-block;
          
          border-radius: 4px;
          margin: 4px auto;
          height: 40px;
          animation: 0.5s anim-skeleton-loader infinite alternate;

          &-2 {
            animation-delay: -100ms;
          }

          &-3 {
            animation-delay: -200ms;
          }

          &-4 {
            animation-delay: -300ms;
          }

          &-4 {
            animation-delay: -400ms;
          }

        }

        &.skeleton-row {
          animation: 0.5s anim-row-bottom infinite alternate;

          &-2 {
            opacity: 0.7;
          }

          &-3 {
            opacity: 0.4;
          }

          .mobile-hidden {
            @include media("<=720px") {
              display: none;
            }
          }

          .desktop-hidden {
            @include media(">720px") {
              display: none;
            }
          }
        }
      }
    }
    
    .playback-container {

      position: relative;

      .track-active {
        visibility: hidden;
      }

      .equalizer {
        position: absolute;
        top: 2px;
        left: 2px;
      }

      .controls {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        
        .generate-progress {
          width: 20px;
          height: 20px;
        }
        
        .control-icons {

          visibility: hidden;

          &:hover {
            > svg {
              fill: $lime;
            }
          }

          &.song-active {
            visibility: hidden;
          }

          > svg {
            width: 16px;
            height: 16px;

            fill: $white;
          }
        }
      }
    }
  }

  .empty-library {
    padding: 64px 32px;
    text-align: center;
    border: 1px dashed $lime;
    border-radius: 4px;
    font-size: 1.2rem;
    color: $white;
    font-weight: 500;
    margin: 20px auto;
  }
}

@keyframes animation-toggle-favorite {
  from { transform: scale(1.5) }
  to { transform: scale(1) }
}

@keyframes anim-skeleton-loader {
  from {
    opacity: 1;
    transform: translateY(4%);
  }
  to {
    opacity: 0.8;
    transform: translateY(-2%);
  }
}

@keyframes anim-row-bottom {
  from {
    border-bottom-color: rgba($gray, 0.3);
  }

  to {
    border-bottom-color: rgba($gray, 0.5);
  }
}