@import '../../palette';

// TODO this is tied to create song:
.__create-song {

  .__rhythm-controls {

    background-color: $yellow;
    border-radius: 16px;
    padding: 8px 16px;
    margin-top: 20px;

    .title {
      display: flex;
      font-size: 22px;
      font-weight: bold;
      align-items: center;
      margin: 12px auto 20px;
      justify-content: center;
      }

    .playback-toggle {
      width: 32px;
      height: 32px;
      background-color: $lime;
      border-radius: 100%;
      margin-right: 8px;
      border: 1px solid $purple;
      box-shadow: 0 0 0 2px $olive;
      display: flex;
      padding: 10px;
      align-items: center;
      justify-content: center;
      transition: 0.3s;

      > svg {
        fill: $white;
      }

      &:hover {
        transform: scale(1.05);
      }
    }

    .label-slider {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      .slider-label {
        min-width: 30%;
        font-weight: bold;
        text-align: left;
      }
    }
    .__rhythm-slider {
      
      flex: 1;
      background-color: $red;
      border-radius: 32px;
      display: flex;
      padding: 12px 16px;
      align-items: center;

      display: flex;
      justify-content: space-between;
      color: white;

      .adjust-button {
        user-select: none;
        background-color: white;
        display: flex;
        justify-content: center;
        border-radius: 100%;
        color: black;
        width: 26px;
        height: 26px;
        align-items: center;
        font-weight: bold;
      }
    
      .rc-slider {
        flex: 1;
        padding-top: 1px;
    
        &-rail {
          height: 12px;
        }
    
        &-track {
          background-color: $lime;
          height: 12px;
        }
    
        &-handle {
          border: 2px solid $purple;
          margin-top: -4px;
          height: 20px;
          width: 20px;
          border-radius: 50%;
        }
      }
    }
  }
}
