@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__potential-investors {
  min-height: 100vh;
  background-color: $dark_green;
  display: flex;
  align-items: center;

  .background {
    position: absolute;
    overflow: hidden;
    height: 100%;
    right: 0;

    svg {
      height: 100%;
    }
  }

  .content {
    width: 680px;
    max-width: 88%;
    margin: 0 auto;

    .logo {
      display: block;
      width: 220px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }

    .copy {
      background-color: white;
      color: black;
      border-radius: 48px;
      padding: 40px;
      font-family: $roboto;
      box-shadow: 12px 12px 0 0 $orange;
      font-size: 18px;
      line-height: 28px;
      min-height: 532px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      z-index: 2;
      position: relative;

      @include media("<=720px") {
        padding: 20px;
      }

      h1 {
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 28px;

        @include media("<=720px") {
          font-size: 28px;
          line-height: 36px;
        }
      }

      .form-inner {
        background-color: $lightest_gray;
        border-radius: 24px;
        padding: 24px 16px;
        margin: 32px 0 16px;

        label {
          font-weight: bold;
        }

        input[type=email] {
          display: block;
          width: 100%;
          background-color: transparent;
          border: none;
          border-radius: 0;
          border-bottom: 1px solid $gray;
          font-size: 18px;
          color: $dark_gray;
          margin: 6px 0 20px;
          font-family: $roboto;

          &:focus {
            outline: none;
            border-bottom-color: $dark_orange;
            transition: 0.3s border-bottom-color;
          }
        }

      }
      
      button {
        border: none;
        border-radius: 32px;
        color: $dark_green;
        background-color: $lime;
        font-size: 24px;
        font-weight: bold;
        display: block;
        margin: 0 0 0 auto;
        padding: 12px 32px;
      }

      .chill-icon {
        display: block;
        margin: 20px auto;
        width: 128px;
      }
    }
  }
}
