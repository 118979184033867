@import '../../palette';

.__feedback {

  &-container {
    padding: 8px;
    position: fixed;
    right: 16px;
    bottom: 16px;
    cursor: pointer;
  }

  &-button {
    background-color: #c75871;
    width: 48px;
    height: 48px;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    box-shadow: 0 2px 4px 1px rgba(black, 0.2);
    z-index: 1000;
  }

  &-tooltip {
    font-family: 'Montserrat', sans-serif;

    .tooltip-inner {
      padding: 8px;
      text-align: left;
    }
  }
}