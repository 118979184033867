@import '../../palette';
@import 'node_modules/include-media/dist/_include-media.scss';

.__pricing-page {
  
  section.pricing {
    max-width: 1000px;
    margin: 0 auto;
    
    @include media("<=900px") {
      margin-bottom: 32px;
    }
  }

  section.details {
    background-color: $olive;
    
    > div {
      margin: 0 auto;
      max-width: 700px;
      padding: 12px;
    }
  }

  section.divider {
    display: flex;

    .left-bg {
      background: linear-gradient(to top, $olive 89%, transparent 89%);
      flex: 1;
    }

    .right-bg {
      background: linear-gradient(to top, $olive 19%, transparent 19%);
      flex: 1;
    }
    
    .lines {
      display: flex;
      height: 100px;
      width: 980px;
    }

    .line-left {
      flex: 2;
      background: linear-gradient(22deg, $olive 37%, transparent 37%);
      margin-top: -52px;
    }
    
    .line-middle {
      flex: 5;
      background: linear-gradient(-6deg, $olive 37%, transparent 37%);
    }
    
    .line-right {
      flex: 2;
      background: linear-gradient(10deg, $olive 37%, transparent 37%);
      margin-top: -18px;
    }

    @include media("<=980px") {
      height: 60px;
      
      transform: rotate(3deg);
      background-color: $olive;
      margin-bottom: -24px;
      margin-left: -4px;
      margin-top: 32px;

      .lines, .left-bg, .right-bg {
        display: none;
      }
    }
  }

  .header {
    padding-top: 32px;
    background-color: $green;
    z-index: 2;
    font-family: $fredoka;
    margin: 0px auto 40px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    letter-spacing: 1px;
    text-align: center;

    > svg {
      fill: $orange;
      margin-right: 8px;

      @include media("<=720px") {
        display: none;
      }
    }
  }

  .trial-non-overflow {
    overflow: hidden;
    width: 100%;
  }

  .start-trial-container {
    margin: 12px;
    margin-top: 40px;
    position: relative;
    background-color: $dark_green;
    border-radius: 24px;
    padding: 40px;
    padding-left: 320px;

    @include media("<=720px") {
      padding: 8px;
      margin-top: 80px;
    }

    .badge {
      position: absolute;
      left: -10%;
      top: 0;

      @include media("<=720px") {
        transform: scale(0.35);
        transform-origin: left top;
        left: -16%;
        top: -8%;
      }
    }

    h2 {
      color: $orange;
      text-align: center;
      font-size: 42px;

      @include media("<=720px") {
        font-size: 24px;
        margin-top: 40px;
        margin-left: 80px;
      }
    }

    .benefits {
      margin: 20px auto 60px;
      text-align: center;
      font-size: 20px;
      
      > div {
        margin-bottom: 16px;
      }

      @include media("<=720px") {
        margin: 72px 0 32px;
        font-size: 16px;
      }
    }

    .start-trial-button {
      background-color: $orange;
      font-size: 40px;
      font-weight: bold;
      border-radius: 40px;
      padding: 8px 40px;
      border: none;
      margin-top: 60px;

      @include media("<=720px") {
        margin-top: 32px;
        font-size: 28px;
        padding: 8px 24px;
      }
      
      &:hover, &:active {
        background-color: $orange;
      }

      &:hover {
        transform: scale(1.02);
        transition: 0.3s transform;
      }
    }
  }

  h5 {
    font-style: italic;
    font-weight: normal;

    &.new-ways {
      margin: 48px 0;
    }
  }
  
  .yearly-membership {
    padding-top: 40px;
    
    h1 {
      font-family: $fredoka;
      text-align: center;
      text-transform: uppercase;
      font-size: 48px;
      letter-spacing: 2px;
    }

    .bill-cycle-select {
      display: flex;
      width: 320px;
      background-color: $medium_light_green;
      border-radius: 24px;
      padding: 8px;
      gap: 8px;
      align-items: stretch;
      margin: 32px auto;
      
      div[role=button] {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px 4px;
        color: black;
        border-radius: 20px;
        font-family: $roboto;
        
        &.active {
          background-color: $orange;
          transition: 0.3s background-color;
        }

        &:hover:not(.active) {
          background-color: rgba($light_green, 0.5);
          transition: 0.3s background-color;
        }

        h3 {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 2px;
          color: $white;
        }
      }
    }

    .plans {
      display: flex;
      margin: 60px auto;
      width: 1000px;
      max-width: 92%;
      
      @include media("<=720px") {
        max-width: 82%;
        flex-direction: column;
      }

      &-container {
        margin: 0 auto;
      }

      .plan {
        padding: 32px 32px 100px 32px;
        flex: 1;
        position: relative;

        h3 {
          font-family: $roboto;
          text-align: center;
          font-weight: bold;
          font-size: 26px;
          line-height: 36px;
          margin-bottom: 20px;
        }

        .price {
          margin: 24px 0;

          h3 {
            margin-bottom: 0px;

            .cadence {
              font-size: 18px;
              font-weight: normal;
            }
          }

          .small-print {
            font-family: $roboto;
            font-style: italic;
            font-size: 15px;
            text-align: center;
            opacity: 0.6;
          }
        }

        ul {
          margin-block-start: 1.8rem;
          margin-block-end: 1.2rem;
          margin-inline-start: 12px;
          padding-inline-start: 12px;

          li {
            margin-bottom: 8px;
          }
        }

        .signup-button {

          &-container {
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 40px;

            @include media("<=720px") {
              padding: 20px;
            }
          }

          display: inline-block;
          font-family: $roboto;
          color: white;
          border-radius: 40px;
          min-width: 172px;
          white-space: nowrap;
          text-align: center;
          padding: 12px 20px;
          font-weight: bold;
          font-size: 18px;
          border: none;

          @include media("<=720px") {
            margin-bottom: 20px;
          }
        }

        button.signup-button {
          cursor: pointer;

          &:hover {
            opacity: 0.8;
            transition: 0.3s opacity;
          }
        }

        &.free {
          background-color: $dark_green;
          border-top-left-radius: 32px;
          border-bottom-left-radius: 32px;
          margin-right: 12px;

          .signup-button {
            background-color: $olive;    
          }

          @include media("<=720px") {
            border-bottom-left-radius: 0;
            border-top-right-radius: 32px;
            margin-right: 0;
          }
        }

        &.standard {
          background-color: $yellow;
          margin: -24px;
          color: black;
          border-radius: 60px;
          z-index: 2;

          .signup-button {
            background-color: $orange;

            &-container {
              padding: 40px 40px 64px 40px;

              @include media("<=720px") {
                padding: 40px 40px 20px 40px;
              }
            }
          }

          @include media("<=720px") {
            margin: -16px;
          }
        }

        &.pro {
          background-color: $dark_green;
          border-top-right-radius: 32px;
          border-bottom-right-radius: 32px;
          margin-left: 12px;

          .signup-button {
            background-color: $purple;
          }

          @include media("<=720px") {
            border-top-right-radius: 0;
            border-bottom-left-radius: 32px;
            margin-left: 0;
          }
        }
      }
    }
  }
}

section.__get-coins-faq {
  max-width: 700px;
  padding: 12px;
  margin: 20px auto;

  .question {
    h3 {
      margin: 40px 0 20px;
      text-transform: uppercase;
      font-weight: 500;
      color: $yellow;
      font-size: 1.6rem;

      @include media("<=720px") {
        font-size: 1.3rem;
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 2rem;

      @include media("<=720px") {
        font-size: 1.1rem;
        line-height: 1.8rem;
      }
    }
  }

  a.start-trial {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    font-weight: 700;
    font-size: 22px;
    background-color: $orange;
    border: none;
    padding: 12px 20px;
    margin: 20px auto;

    &:hover, &:active {
      color: $white;
      text-decoration: none;
      transition: 0.3s;
    }

    svg {
      margin-right: 6px;
    }
  }
}

.__welcome-premium-modal {
  .modal-header {
    font-size: 38px;
    line-height: 42px;
    font-weight: bold;
    color: $red;
    text-align: center;
    border-bottom: none;
    padding-left: 60px;

    @include media("<=400px") {
      padding-left: 0;
    }
  }

  .modal-body {
    text-align: center;
    padding: 8px 20px 8px 100px;
    font-size: 15px;

    @include media("<=400px") {
      padding: 20px;
    }
  }

  .modal-footer {
    border-top: none;
  }

  .avatar {
    position: absolute;
    top: -12%;
    left: -100px;
    width: 200px;
    height: 200px;

    @include media("<=400px") {
      display: none;
    }
  }
}