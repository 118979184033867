@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__comments {
  text-align: left;
  margin-bottom: 40px;

  .comment-count {
    font-size: 1.2em;
    margin-bottom: 12px;
    font-weight: 500;
  }

  .view-more-replies {
    .btn-link {
      color: $light_orange;
      padding: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .loading-main-comments {
    text-align: center;

    .__infinite-loader {
      width: 32px;
    }
  }

  .entry {
    margin: 20px 0;

    .header-controls {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header {
        display: flex;
        gap: 8px;
        align-items: baseline;
      }
      
      .display-name {
        font-size: 0.9em;
        font-weight: bold;
      }
    
      .date {
        color: $light_gray;
        font-size: 0.8em;
      }

      .controls-toggle {
        user-select: none;
        color: $light_yellow;
        font-weight: bold;

        &:hover {
          color: $yellow;
        }
      }
    }

    .message {
      margin: 8px 0;

      &.reported {
        color: $gray;
        font-size: 0.9rem;
      }
    }
    
    .report-message {
      font-weight: 500;
      color: $light_red;
    }

    .controls {
      display: flex;
      gap: 4px;
      align-items: center;
      margin-bottom: 4px;

      .likes {
        display: flex;
        gap: 6px;
        font-size: 0.9rem;
        color: $light_gray;
        user-select: none;
  
        > svg {
          width: 16px;

          &.active {
            > path {
              fill: $lime;
            }
          }
        }
      }

      .reply-btn {
        color: $light_orange;
      }
    }

    .view-replies {
      display: flex;
      font-weight: bold;
      color: $light_orange;
      font-size: 0.8rem;

      .arrow {
        margin-top: 7px;
        margin-right: 6px;
        border: 6px solid transparent;
        border-top-color: $light_orange;
        display: inline-block;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .initial-load {
      margin: 12px 0 20px;
      text-align: center;

      > svg {
        width: 24px;
      }
    }

    .nested-comments {
      border-left: 2px solid $olive;
    }
  }

  &-form {
    padding: 8px 0;

    .comment-message {
      background-color: rgba($dark_green, 0.6);
      // border: 2px solid $green;
      border: none;
      color: $white;
      margin-bottom: 8px;
      font-size: 0.9rem;
      border-radius: 12px;
      resize: none;
      padding: 8px;

      &::placeholder {
        color: $light_green;
      }

      &:focus {
        box-shadow: none;
        background-color: rgba($dark_green, 0.8);
      }
    }

    .form-controls {

      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn {
        color: $white;
        font-size: 0.9rem;        
        font-weight: 500;
      }

      .btn-link {
        &:hover {
          color: $yellow;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .btn-primary {
        border-radius: 32px;
        padding: 6px 12px;
        border: none;
        background-color: $red;

        &:hover:not(:disabled) {
          background-color: $dark_red;
        }
    
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba($red, 0.5);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
          background-color: $dark_red;
          box-shadow: 0 0 0 0.2rem rgba($red, 0.5);
        }
      }

      .pending {
        flex: 1;
      }
    }
  }
}
