@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__track-editor {

  @include media("<=720px") {
    display: none;
  }

  flex: 1;
  margin: 20px;

  .main-controls {
    display: flex;
    margin-right: 24px;
    margin-bottom: 48px;
  }

  .play-control {
    width: 32px;
    height: 32px;
    transition: 0.3s all;

    &:hover {
      
      > svg {
        transition: 0.3s all;
        fill: $lime;
      }
    }

    > svg {
      fill: $white;
      height: 100%;
      width: 100%;
      animation: 0.5s animation-toggle-play;
    }
  }

  &-grid {
    display: grid;
    gap: 4px 8px;
    // grid-auto-rows: 60px;
    margin-top: 40px;
    user-select: none;
    
    // TODO -> set all rows, so that new ones can be customized:
    // grid-template-rows: 12px;
    margin-bottom: 60px;

    .playback-seek {
      // background-color: #dddddd;
      grid-column: 2 / -1;
      // border-radius: 8px;
    }

    .separator-row {
      grid-column: 1 / -1;
      padding: 6px 0;
      margin: 0;
      position: relative;

      &:hover {
        .add-row {
          visibility: visible;
        }
      }

      .separator-line {
        border-bottom: 1px solid $gray;
      }

      .add-row {
        position: absolute;
        background-color: white;
        border-radius: 100%;
        color: $green;
        width: 24px;
        height: 24px;
        font-size: 28px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        visibility: hidden;
      }
    }

    .instrument {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 4px 4px;
      position: relative;
      border: 1px solid transparent;

      .rc-slider {
        height: 48px;
      }

      &.dragging {
        background-color: $green;
        border-color: rgba(172, 172, 172, 0.8);
        z-index: 100;
        box-shadow: 4px 4px 8px 2px rgba(0, 0, 0, 0.25);
      }


      &-row {
        display: contents;
      }

      &:hover {
        .name .regenerate-button {
          visibility: visible;
        }

        .delete-instrument {
          visibility: visible;
        }
      }

      .delete-instrument {        
        position: absolute;
        top: -20px;
        left: 0px;
        font-weight: 600;
        font-size: 26px;
        visibility: hidden;
      }

      .drag-handle {
        padding: 4px;
        margin-bottom: 8px;
        margin-left: 4px;
        cursor: grab;

        > svg {
          width: 10px;
          height: auto;
        }
      }

      .name {
        flex: 1;

        .mute-controls {
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .regenerate-button {

          &-container {
            width: 24px;
            height: 24px;
            margin-top: 2px;
          }

          border-radius: 50%;
          display: flex;
          padding: 2px;
          border: 1px solid $light_lime;
          visibility: hidden;
          
          > svg {
            fill: $light_lime;
            width: 100%;
            height: 100%;
          }

          &.regenerating {
            animation: animation-rotate 1s linear infinite;
            visibility: visible;
            cursor: initial;
          }
        }
      }

      .rc-slider {
        .rc-slider-handle {
          border: solid 2px $dark_yellow;
        }

        &-rail {
          // height: 8px;
          // width: 20px;
        }

        &-track {
          background-color: $yellow;
          // height: 8px;
        }
      }

      .drum-title {
        flex: 1;
      }

      .regenerate-progress {
        display: inline-flex;
        flex: 1;
        align-items: center;
        gap: 4px;
        color: $light_gray;
        font-size: 0.8rem;
        height: 82px;

        .loader {
          width: 24px;
          height: 24px;
          margin-inline-start: 4px;
          margin-inline-end: 8px;
        }
      }

      .instrument-select {
        background-color: $dark_green;
        color: white;
        border: 1px solid #ced4da;
        display: flex;
        justify-content: space-between;
        text-align: left;
        padding: 10px;
        font-size: 0.75rem;
        border-radius: 8px;
        align-items: center;
        gap: 8px;
        margin-inline-end: 8px;
        transition: 0.3s;
        width: 240px;
        height: 82px;

        .name {
          flex: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &:hover {
          border-color: white;
          filter: contrast(1.05);
        }

        .dropdown-arrow {
          font-size: 18px;
          line-height: 18px;
          transform: scaleY(0.5) translateY(15%);
          margin-right: 2px;
          margin-left: 2px;
          font-weight: bold;
        }

        .instrument-icon {
          > svg {
            width: 56px;
            height: 56px;
          }
        }
      }

      .track-mute-btn {
        fill: white;

        .__track-mute-icon_cls {
          stroke: white;
          fill: transparent;
        }

        &:hover:not(.disabled):not(.active) {
          fill: $orange;

          .__track-mute-icon_cls {
            stroke: $orange;
            fill: transparent;
          }
        }

        &.active {
          fill: $white;
          transition: 0.3s;

          .__track-mute-icon_cls {
            stroke: $white;
            fill: $light_purple;
          }
        }

        &.disabled {
          opacity: 0.3;
          user-select: none;
        }
      }

      .track-solo-btn {
        border-radius: 24px;
        font-size: 12px;
        padding: 4px 6px;
        border: 1px solid white;

        &:hover {
          color: $orange;
          border-color: $orange;
          background-color: transparent;
        }

        &.active {
          background-color: $light_purple;
          color: white;
          border-color: white;
          transition: 0.3s;
        }
      }

      .rc-slider.track-volume {

        margin-right: 12px;
        
        .rc-slider {
          &-rail {
            width: 8px;
            background-color: white;
          }

          &-track {
            width: 8px;
            background-color: $lime;
          }

          &-handle {
            margin-left: -3px;

            background-color: white;
            border-color: $light_purple;
          }
        }
      }
    }

    .section {
      background-color: $papaya;
      overflow: hidden;
      position: relative;
      height: 48px;
      align-self: center;
      z-index: 0; // @MB-130: safari needs z-index: 0, else absolute elements overflow

      &:hover {
        .section-volume-adjust {
          visibility: visible;
        }

        .section-mute {
          visibility: visible;
          opacity: 1;
        }

        .section-regenerate {
          visibility: visible;
        }

        .custom-note-sequence {
          visibility: visible;
        }
      }

      &.odd {
        .section-volume {
          background-color: $orange;
        }
      }

      &.first {
        border-top-left-radius: 32px;
        border-bottom-left-radius: 32px;

        .custom-note-sequence {
          left: 16px;
        }
        
        .section-regenerate {
          left: 10px;

          &.with-sequence {
            left: 16px;
          }
        }
      }

      &.last {
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;

        .section-mute {
          right: 10px;
        }
      }

      &.section-adjusting-volume {
        .section-volume-adjust {
          visibility: visible;
        }
      }
      
      &.finished {

        .section-playing {
          animation: none;
        }
      }

      &.track-off {
        background-color: $lighter_gray;

        .section-playing {
          background-color: $light_gray;
        }
      }

      &.narrow {
        .section-mute {
          bottom: 2px;
          right: 2px;
          width: 22px;
        }

        .section-regenerate {
          top: 2px;
          left: unset;
          right: 2px;
          width: 20px;
          height: 20px;

          &.with-sequence {
            bottom: unset;
            left: unset;
            right: 2px;
          }
        }

        .custom-note-sequence {
          top: 50%;
          left: 2px;
          transform: translateY(-50%);
          line-height: 12px;
          font-size: 10px;
          padding: 2px 4px;
          min-width: 16px;
        }

        &.last {
          .section-mute {
            left: 2px;
            right: unset;
          }

          .section-regenerate {
            left: 2px;
            right: unset;
          }

          .custom-note-sequence {
            left: unset;
            right: 2px;
            transform: translateY(-50%);
          }  
        }
      }

      .section-playing {
        width: 0%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,0.2);
        z-index: 2;
        animation: animation-playing 1s infinite alternate ease-out;

        transition: 0.2s linear;
      }

      .section-volume {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $red;

        &-adjust {
          position: absolute;
          height: 10px;
          left: 0;
          right: 0;
          cursor: grab;
          z-index: 10;
          visibility: hidden;
          transform: translateY(-5px);
          display: flex;
          align-items: center;

          &-line {
            height: 8px;
            background-color: white;
            width: 100%;
          }
        }
      }

      .section-mute {
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 28px;
        height: 20px;
        font-size: 0.5rem;
        font-weight: bold;
        visibility: hidden;
        opacity: 0;
        z-index: 100;
        background-color: $purple;
        padding: 2px 4px;
        border-radius: 12px;

        > svg {
          width: 100%;
          height: 100%;
          fill: white;
        }

        &:hover {
          background-color: $light_purple;
        }
      }

      .section-regenerate {
        position: absolute;
        bottom: 4px;
        left: 4px;
        width: 22px;
        height: 22px;
        background-color: $purple;
        border-radius: 50%;
        display: flex;
        padding: 2px;
        visibility: hidden;
        z-index: 100;

        > svg {
          width: 100%;
          height: 100%;
          fill: white;
        }

        // when the [+] tab for selecting note sequences is on, show a bit lower:
        &.with-sequence {
          bottom: 2px;
          left: 2px;
        }

        &.section-regenerating {
          animation: animation-rotate 1s linear infinite;
          visibility: visible;
          cursor: initial;
        }

        &:hover {
          background-color: $light_purple;
        }
      }

      &.section-regenerate-flash {
        animation: animation-flash 1s linear;
      }

      .custom-note-sequence {

        position: absolute;
        left: 2px;
        top: 2px;
        background-color: $purple;
        line-height: 14px;
        font-size: 12px;
        font-weight: bold;
        min-width: 20px;
        text-align: center;
        border-radius: 4px;
        padding: 2px 4px;
        z-index: 100;
        visibility: hidden;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 90%;

        &.has-selected-sequence {
          visibility: visible;
        }
      }
    }

    .loop-marker {

      text-align: center;
      margin-top: 8px;

      &:hover {
        .loop-counter .edit-count {
          visibility: visible;
        }
      }

      &-button {
        background-color: $dark_gray;
        border-radius: 32px;
        transition: 0.3s;
        cursor: pointer;
        box-shadow: 1px 1px 2px 0px rgba(black, 0.2);

        &:hover {
          filter: brightness(1.1);
        }

        > svg {
          width: 32px;
        }

        &.loop-enabled {
          background-color: $light_purple;
  
          .loop-icon {
            fill: white;
          }
  
          .loop-counter {
            color: white;
            visibility: visible;
          }
        }
      }

      .loop-icon {
        margin: 4px;
        fill: $light_gray;
      }

      .loop-counter {
        display: flex;
        font-weight: bold;
        font-size: 14px;
        color: $white;
        margin: 8px auto;
        max-width: 160px;
        justify-content: space-between;
        user-select: none;

        &.show-buttons {
          .edit-count {
            visibility: visible;
          }
        }

        .edit-count {
          border-radius: 50%;
          border: 1px solid transparent;
          display: flex;
          width: 22px;
          font-size: 16px;
          height: 22px;
          align-items: center;
          justify-content: center;
          color: $white;
          visibility: hidden;

          &.disabled {
            color: $light_gray;
            pointer-events: none;
          }

          &:hover {
            border-color: lightgray;
          }
        }
      }
    }

    .rc-slider.editor-playback {
      margin-top: 5px;

      &.seeking {
        .rc-slider-track {
          transition: none;
        }
      }

      .rc-slider-handle {
        margin-top: -4px;
        border-color: $dark_orange;

        height: 20px;
        width: 20px;
        border-radius: 50%;
        visibility: hidden;
      }

      .rc-slider-rail {
        height: 12px;
      }

      .rc-slider-track {
        background-color: $orange;
        height: 12px;
        transition: 0.2s all linear;
      }

      .rc-slider-dot {
        height: 12px;
        width: 12px;
      }

      .rc-slider-step {

        .rc-slider-dot {
          bottom: inherit;
          border-color: #75b0ff;

          &:first-of-type, &:last-of-type {
            opacity: 0;
          }

          &-active {
            border-color: #2e84f5;
          }
        }
      }

      .rc-slider-mark {
        top: 20px;
      }

      &:hover {
        cursor: pointer;
        
        .rc-slider-handle {
          visibility: visible;
        }
      }
    }
  }
}

@keyframes animation-playing {
  from { opacity: 0.7 }
  to { opacity: 1 }
}

@keyframes animation-rotate {
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
}

@keyframes animation-flash {
  from { filter: hue-rotate(45deg) brightness(1.2); transform: scale(1.1); }
  to { filter: hue-rotate(0deg) brightness(1); transform: scale(1); }
}

@keyframes animation-toggle-play {
  from { transform: scale(1.1) }
  to { transform: scale(1) }
}
