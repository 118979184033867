@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__investor-invite-modal {
  .form-group.two-column {
    display: flex;
    align-items: center;
    gap: 8px;

    > label {
      flex: 2;
      margin-bottom: 0;
    }

    > input {
      flex: 3;
    }
  }

  input.permalink-preview {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    border-radius: 20px;
    background-color: $white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .__checkbox {
    color: $dark_gray;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  
  .errors {
    color: $red;

    p {
      margin: 0;
    }
  }
}
