@import '../../palette';
@import 'node_modules/include-media/dist/_include-media.scss';

.__coupon {
  display: flex;
  width: 600px;
  margin: 32px auto;
  position: relative;
  max-width: 96%;

  // change later
  min-height: 260px;

  .corner {
    position: absolute;
    width: 48px;
    height: 48px;
    background-color: $green;
    border-radius: 100%;

    &-top-right {
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
    }

    &-bottom-right {
      right: 0;
      bottom: 0;
      transform: translate(50%, 50%);
    }

    &-top-left {
      top: 0;
      left: 0;
      transform: translate(-50%, -50%);

      @include media("<=720px") {
        display: none;
      }
    }

    &-bottom-left {
      bottom: 0;
      left: 0;
      transform: translate(-50%, 50%);

      @include media("<=720px") {
        display: none;
      }
    }
  }

  .tag {

    &-container {
      display: flex;
      flex: 1;

      @include media("<=720px") {
        display: none;
      }

      &.redeemed {
        transform: rotate(-10deg) translate(-45%, 1%);
        transition: 0.5s;
        
        .tag {
          border-color: $green;
        }
      }  
    }
    
    &-curve-border {
      width: 12px;
      background-size: contain;
      transform: scaleX(-1);
      // to avoid showing semi transparent <1px gap:
      margin-right: -2px;
    }

    background-color: $yellow;
    position: relative;
    flex: 1;
    border-right: 2px dashed transparent;
    padding: 32px 16px;
    display: flex;

    .amount {
      transform: rotate(180deg);
      writing-mode: vertical-rl;
      background-color: $orange;
      text-align: center;
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-family: $fredoka;
      border-bottom-right-radius: 32px;
      border-top-right-radius: 32px;
      padding: 2px 6px;
      width: 100%;
    }
  }

  .content {
    flex: 3;
    background-color: $yellow;
    padding: 32px 32px 32px 48px;
    display: flex;
    position: relative;
    border-left: 2px dashed $green;

    @include media("<=720px") {
      border-left: none;
      padding: 24px 20px;
    }

    &-inner {
      background-color: $orange;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      padding: 8px 12px;
      flex: 1;

      @include media("<=720px") {
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;  
      }
    }

    .title {
      display: flex;
      font-size: 2rem;
      font-family: $fredoka;
      align-items: center;
      line-height: 2.2rem;
      margin-bottom: 20px;

      &.smaller {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }

      > svg {
        width: 72px;
        height: 72px;
        margin-inline-end: 12px;
      }

      > div {
        flex: 1;
      }
    }

    input {
      width: 50%;
      margin: 20px auto;
    }

    .description {
      margin: 20px auto;
      text-align: center;
      font-weight: 600;
      font-size: 1.3em;
    }

    .redeem-btn {

      &-container {
        text-align: center;
        margin: 20px auto;
      }

      display: inline-flex;
      padding: 6px 12px;
      border-radius: 32px;
      align-items: center;
      text-align: center;
      background-color: transparent;
      border: 2px dashed $white;
      font-size: 1.1em;
      font-weight: bold;
      color: $white;

      > svg {
        margin-inline-end: 8px;
        width: auto;
        height: 24px;
      }

      &:hover:enabled {
        transform: scale(1.05);
        transition: 0.3s;
        background-color: rgba($dark_orange, 0.3);
      }
    }
    
    .redeemed {
      display: inline-block;
      background-color: $red;
      color: $white;
      border-radius: 32px;
      padding: 8px 24px;
      text-align: center;
      font-size: 0.9rem;
      font-weight: bold;
    }

    .valid-until {
      text-align: center;
      font-style: italic;
      font-size: 0.8em;
    }

    &-curve-border-mobile {
      width: 12px;
      background-size: contain;
      transform: scaleX(-1);
      // to avoid showing semi transparent <1px gap:
      margin-right: -1px;

      @include media(">720px") {
        display: none;
      }
    }
    
    &-curve-border {
      width: 12px;
      background-size: contain;
      // to avoid showing semi transparent <1px gap:
      margin-left: -1px;
    }
  }

  .expired {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-10deg) scaleY(1.15);
    font-size: 4.8rem;
    line-height: 4.8rem;
    text-transform: uppercase;
    color: $red;
    border-top: 2rem solid $red;
    border-bottom: 2rem solid $red;
    background-color: $white;
    padding: 2px 16px;
    font-weight: bold;
    user-select: none;
  }
}
