@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__feedback {
  max-width: 900px;
  margin: 0 auto;
  padding: 12px;

  .header {
    background-color: $green;
    z-index: 2;
    font-family: $fredoka;
    margin: 0px auto 40px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    letter-spacing: 1px;
    text-align: center;
  }

  .options {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;

    div {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  
    .filters {
      display: flex;
  
      .feedback_type-input {
        margin-right: 20px;
      }
  
      .form-control {
        background-color: $green;
        border: 1px solid $white;
        color: $white;
      }
    }
  }

  .empty-feedback {
    border: 3px dashed $dark_red;
    padding: 60px 16px;
    border-radius: 4px;
    text-align: center;
    line-height: 2.4rem;
    font-size: 1.1em;
  }

  .initial-load {
    text-align: center;
    margin: 48px auto;

    .__infinite-loader {
      margin-bottom: 20px;
    }
  }

  .feedback-message {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: $dark_green;
    color: $white;

    .creator {
      display: flex;
      justify-content: space-between;
      font-weight: 600;
    }

    .created-at {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .feedback_status-input{
      width: 135px;
      background-color: $dark_green;
      color: $white;
    }

    .message-options {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
    }
  }
}
