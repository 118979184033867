@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__note-sequence-stats {
  width: 800px;
  max-width: 96%;
  margin: 96px auto 0;

  h1.header {
    font-family: $fredoka;
    margin: 0px auto 40px;
    text-transform: uppercase;
    font-size: 2.2rem;
    letter-spacing: 1px;
    text-align: center;
  }

  .loading {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .style {
    margin-bottom: 60px;

    .style-header {
      display: flex;
      justify-content: center;
      align-items: center;

      div:first-child {
        border-radius: 15px 0 0 15px;
      }
      div:last-child {
        border-radius: 0 15px 15px 0;
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 200px;
        background-color: $orange;
        height: 50px;
        font-size: 18px;
        font-weight: bold;
      }
    }

    .category {
      border-bottom: 1px solid $white;
      width: 400px;
      margin: 0px auto;

      .category-header {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        div {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 200px;
          height: 60px;
          font-size: 15px;
        }
      }

      .dynamics{
        margin-bottom: 20px;

        .dynamic {
          display: flex;
          justify-content: center;
          align-items: center;

          div {
            font-size: 13px;
            display: flex;
            justify-content: center;
            width: 200px;
          }
        }
      }
    }
  }
}
