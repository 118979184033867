@import '../../palette';

.__filter-modal {
  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    font-size: 0.9rem;

    > label {
      flex: 1;
      margin: 0;
    }

    > span {
      flex: 1;
    }

    .form-control {
      font-size: 0.9rem;
      flex: 1;
      border-radius: 24px;
    }

    .__checkbox {

      margin: 0;
      display: inline-block;

      &-toggle {
        input {
          &:checked + .__checkbox-slider {
            background-color: $lime;
    
            &:before {
              border-color: rgba(black, 0.8);
              transform: translateX(20px);
            }
          }

          &:focus + .__checkbox-slider {
            box-shadow: 0 0 0px 2px rgba($lime, 0.1);
          }
    
          &:active + .__checkbox-slider {
            box-shadow: 0 0 0px 2px rgba($lime, 0.2);
          }
        }
      }

      &-slider {
        margin-top: 3px;
        margin-bottom: 3px;

        &:before {
          top: -3px;
          border-width: 2px;
        }
      }
    }
  }
}