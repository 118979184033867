.__checkbox {
  color: white;
  margin: 8px 0;
  line-height: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &-text {
    margin: 0 8px;
  }

  &-disabled {
    color: #b9b9b9;
  }

  &-toggle {

    display: inline-block;
    position: relative;
    width: 40px; 
    height: 20px;

    > input {
      width: 0;
      height: 0;
      opacity: 0;

      &:focus + .__checkbox-slider {
        box-shadow: 0 0 0px 2px rgba(#1260fd, 0.25);
      }

      &:active + .__checkbox-slider {
        box-shadow: 0 0 0px 2px rgba(#1260fd, 0.3);

        .__checkbox-disabled & {
          box-shadow: none;
        }
      }
      
      &:checked + .__checkbox-slider {
        background-color: #6FCF97;

        &:before {
          border-color: rgba(#27AE60, 0.8);
          transform: translateX(20px);
        }
      }
    }
  }

  &-slider {
    cursor: pointer;
    position: absolute;
    background-color: #d6d6d6;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px;
    box-shadow: inset 0px 1px 2px rgba(black, 0.1);
    transition: 0.3s;

    &::before {
      position: absolute;
      content: "";
      width: 20px;
      height: 20px;
      background-color: white;
      border: 1px solid #c8c8c8;
      border-radius: 50%;
      left: 0;
      top: 0;
      transition: 0.3s;
    }
  }
}
