@import '../palette';

.__sso {

  margin: 12px 0;
  &-separator {
    display: flex;
    align-items: center;
    margin: 16px 0;

    &-lines {
      flex: 1;
      border-top: 1px solid $light_gray;
    }

    &-or {
      margin: 0 8px;
    }
  }

  &-btn {

    &-fb {
      background-color: $facebook_blue;
    }

    &-discord {
      background-color: $discord_purple;
    }

    &-roblox {
      background-color: $roblox_black;
      border-color: $roblox_black;
    }

    &.btn-primary {
      margin: 4px auto;
      width: 100%;
      padding: 8px;
      
      .btn-inner {
        justify-content: center;
        align-items: center;
        display: flex;
        max-width: 260px;
        margin: 0 auto;
      }
  
      .logo {

        flex: 1;

        > img {
          height: 24px;
          margin: 2px;
        }
      }

      .text {
        flex: 5;
      }
    }
  }

  &-google-btn {
    border-radius: 4px !important;
    font-family: inherit !important;
    font-size: inherit !important;
    width: 100%;
    margin: 4px auto;
    justify-content: center;

    > div {
      margin-right: 16px !important;
    }
  }
}
