@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__test-modal {
  .form-group.two-column {
    display: flex;
    align-items: center;
    gap: 8px;

    > label {
      flex: 2;
      margin-bottom: 0;
    }

    > input {
      flex: 3;
    }
  }

  input.permalink-preview {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    border-radius: 20px;
    background-color: $white;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .__checkbox {
    color: $dark_gray;
    justify-content: flex-end;
    margin-bottom: 1rem;
  }
  
  .errors {
    color: $red;

    p {
      margin: 0;
    }
  }

  h3 {
    font-weight: bold;
    font-size: 1.2rem;
    color: $orange;
  }

  .variant {
    display: flex;
    align-items: center;
    justify-content: space-around;

    input {
      width: 90%;
    }

    span {
      font-family: $fredoka;
      font-weight: 500;
      color: $red;
      cursor: pointer;
    }
  }

  .add-variant {
    display: flex;
    justify-content: flex-end;
    font-family: $fredoka;
    font-weight: 500;
    color: $olive;
    cursor: pointer;
  }

  .variant-activation{
    display: flex;
    justify-content: space-between;
  }
}
