@import './palette';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

a {
  color: #f7f5ea;
  text-decoration: none;
}

a:hover {
  color: #fcca75;
  text-decoration: underline;
}

.modal-dialog {
  color: $dark_green;

  a:not(.btn) {
    color: $orange;
  }

  .strong-link {
    color: $orange;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}

.strofe-toast {
  position: fixed;
  top: 20px;
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
  border-radius: 8px;
  background-color: $purple;
  color: white;
}

// TODO -> this overrides all rc slider tooltips, for now it's only used in seek time:
.rc-slider-tooltip {

  &-placement-top .rc-slider-tooltip-arrow {
    border-top-color: $orange;
  }

  &-inner {
    min-width: 36px;
    box-shadow: none;
    background-color: $orange;
  }
}

.__tooltip {
  font-family: $montserrat;
  font-size: 0.75rem;
}
