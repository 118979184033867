@import '../../palette';

.__equalizer {
  background-color: $yellow;
  position: relative;
  box-shadow: 0 4px 4px 1px rgba(black, 0.2);
  border-radius: 8px;
  padding: 8px;

  width: 360px;
  height: 360px;
  max-width: 100%;
  margin: 0 auto;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    .pause-song {
      opacity: 1;
    }
  }

  .bar {
    display: block;
    width: 6px;
    background-color: $red;
    height: 48px;
    border-radius: 4px;
    animation: animation-equalize 8s 0s infinite paused;

    &.playing {
      animation-play-state: running;
    }
  }

  .control {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    background-color: rgba(white, 0.8);
    padding: 40px;
    border-radius: 50%;
  }

  .play-song {    
    > svg {
      margin-left: 4px;
      margin-top: 4px;
    }
  }

  .pause-song {
    opacity: 0;
    transition: 0.3s all;
  }
}

// Inspiration: https://codepen.io/gauldo/pen/MdOoOZ
@keyframes animation-equalize {
  0% {
    height: 108px;
  }
  4% {
    height: 96px;
  }
  8% {
    height: 80px;
  }
  12% {
    height: 60px;
  }
  16% {
    height: 20px;
  }
  20% {
    height: 60px;
  }
  24% {
    height: 80px;
  }
  28% {
    height: 10px;
  }
  32% {
    height: 80px;
  }
  36% {
    height: 108px;
  }
  40% {
    height: 20px;
  }
  44% {
    height: 80px;
  }
  48% {
    height: 108px;
  }
  52% {
    height: 60px;
  }
  56% {
    height: 10px;
  }
  60% {
    height: 60px;
  }
  64% {
    height: 108px;
  }
  68% {
    height: 60px;
  }
  72% {
    height: 108px;
  }
  76% {
    height: 20px;
  }
  80% {
    height: 108px;
  }
  84% {
    height: 38px;
  }
  88% {
    height: 108px;
  }
  92% {
    height: 20px;
  }
  96% {
    height: 108px;
  }
  100% {
    height: 108px;
  }
}