@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__create-song {
  background-color: $green;
  
  &-container-fade {
    animation: 0.6s animation-appear;
  }

  .pick-style {
    text-align: center;
    padding: 20px 12px 12px;

    .mood-prompt {
      color: $white;
      font-weight: bold;
      font-size: 1.7rem;
    }

    h2 {
      font-size: 40px;
      font-weight: 500;
      color: #0564e0;
      text-align: center;
    }

    p {
      font-size: 24px;
      color: #bbbbbb;
    }

    .custom-genre {
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      max-width: 460px;
      margin: 0 auto;
      flex-wrap: wrap;
      gap: 8px;
      
      @include media("<=720px") {
        justify-content: center;
      }
      
      &.genre-disabled {
        opacity: 0.3;
        pointer-events: none;
      }
      
      p {
        margin: 0;
        font-size: 18px;
        margin-inline-end: 24px;
        color: $white;
      }

      .btn {
        padding: 4px 16px;
        border-radius: 16px;
        font-size: 16px;
        color: white;
        border-color: white;
        background-color: transparent;
        min-width: 132px;
        font-weight: bold;

        &:hover {
          background-color: $purple;
        }

        &.active {
          color: white;
          background-color: $orange;
        }

        &.auto {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.custom {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    .moods {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 720px;
      max-width: 100%;
      margin: 0 auto 40px;
    }

    .track-options-container {
      position: relative;
      max-width: 532px;
      margin: 32px auto;

      @include media("<=860px") {
        margin-bottom: 64px;
      }

      &.expanded {
        max-width: 820px;

        .extra-options {
          width: 400px;

          @include media("<=860px") {
            height: 420px;
            width: unset;
          }
        }

        .toggle {
          > svg {
            transform: rotate(180deg);
          }
        }
      }

      .pick-genre-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border: 1px solid $orange;
        padding: 20px;
        border-radius: 40px;
        position: relative;
        z-index: 2;
        background-color: $dark_green;
        align-items: center;

        &.custom-options {
          max-width: 480px;
          min-height: 400px;

          @include media("<=860px") {
            min-height: unset;
            margin: 0 auto;
          }
        }
    
        @include media("<=860px") {
          padding-left: 4px;
          padding-right: 4px;
        }
      }
      
      .extra-options {
        position: absolute;
        z-index: 1;
        right: 32px;
        top: 0;
        bottom: 0;
        width: 52px;
        background-color: $orange;
        border-top-right-radius: 40px;
        border-bottom-right-radius: 40px;
        padding: 24px;
        padding-left: 112px;

        @include media("<=860px") {
          margin: -36px auto 0;
          height: 28px;
          max-width: 480px;
          border-top-right-radius: unset;
          border-bottom-left-radius: 40px;
          padding: 32px 24px 24px 24px;
          position: unset;
          width: 100%;
        }

        .controls {
          color: $dark_green;

          &.disabled {
            opacity: 0.6;
            pointer-events: none;
          }

          .label {
            margin: 12px 0;
            font-size: 22px;
            font-weight: bold;

            &:not(:first-of-type) {
              margin-top: 48px;

              @include media("<=860px") {
                margin-top: 24px;
              }
            }
          }

          .harmony-select {
            border-radius: 16px;
            border: none;
          }
        }

        .toggle {
          position: absolute;
          top: 60px;
          left: 100%;
          width: 40px;
          height: 52px;
          background-color: $orange;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-right: 4px;

          @include media("<=860px") {
            top: 100%;
            left: 50%;
            transform: translateX(-50%) rotate(90deg);
            margin-top: -12px;

          }
        }
      }
    }

    .style-button {
      text-align: center;
      font-size: 20px;
      flex: 1;
      margin: 4px;
      border: 1px solid transparent;
      color: $white;
      
      &.mood {
        font-weight: bold;

        &:hover {
          background-color: rgba(white, 0.1);
        }
  
        &.active {
          background-color: rgba(white, 0.1);
          border-color: transparent;
        }
      }

      &.genre {
        font-size: 16px;
        flex-basis: 25%;
        max-width: 32%;
        border: none;
        // max-width: 112px;

        &.active {
          background-color: transparent;
          border: none;

          .hovered {
            opacity: 1;
          }
  
          .outline {
            opacity: 0;
          }
        }  

        &:active {
          box-shadow: none;
        }

        &:hover {
          background-color: rgba(white, 0.1);

          .hovered {
            opacity: 1;
            transition: 0.3s;
          }

          .outline {
            opacity: 0;
            transition: 0.15s;
          }
        }
        
        .icon-container {
          position: relative;
        }

        .hovered {
          opacity: 0;
        }

        .outline {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .genre-text {
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 20px;
        }

        .text-bossa, .text-orc_pop, .text-retro_futuristic {
          font-size: 14px;
        }

        .anim-star {
          position: absolute;
          z-index: 10;
          animation: animation-anime-icon 2.6s infinite;

          .__star-icon-outline {
            fill: #cfd4d7;
            stroke: #fff;
            stroke-width: 1;
          }

          &-left {
            width: 24px;
            top: 40%;
            left: 2px;
          }
  
          &-top-right {
            width: 16px;
            top: 10%;
            right: 8%;
            animation-delay: 0.3s;
          }

          &-center-right {
            width: 10px;
            top: 35%;
            right: 5%;
            animation-delay: 0.6s;
          }
        }
      }
      
      @include media("<=720px") {
        flex-basis: 25%;
        max-width: 32%;
      }

      .icon {
        font-size: 48px;
        padding: 8px;
      }
    }
  }

  .tempo-marker {
    margin-bottom: 20px;
    text-align: center;

    .label {
      border-radius: 4px;
      padding: 4px;
    }
  }

  .rc-slider {

    &.big-handle {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    &-dot {
      border-color: $light_red;
    }
  
    &-mark-text {
      color: white;
    }

    &-track {
      background-color: $light_red;
    }

    &-handle {
      width: 10px;
      height: 24px;
      margin-top: -10px;
      border-radius: 4px;
      border-color: $light_red;
    }
  }
}

@keyframes animation-appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes animation-anime-icon {
  0% {
    opacity: 0;
    transform: rotate(15deg) scale(0.6);
  }

  3% {
    opacity: 0.3;
    transform: rotate(15deg) scale(1.7);
  }

  50% {
    opacity: 1;
    transform: translateY(-5px) rotate(10deg);
  }

  80% {
    opacity: 1;
    transform: translateY(-5px) rotate(10deg);
  }

  100% {
    opacity: 0;
    transform: translateY(-5px) rotate(10deg);
  }
}