@import '../../palette';

.__master-volume {

  display: flex;
  align-items: center;
  // gap: 16px; // @MB-130

  width: 100%;
  margin-right: 24px;

  > svg {
    margin-left: 16px; // @MB-130 - remove

    width: 20px;
    height: 20px;

    fill: $lime;

    &:hover {
      fill: $light_lime;
    }
  }

  .rc-slider {
    padding-top: 1px;

    &-rail {
      height: 12px;
    }

    &-track {
      background-color: $lime;
      height: 12px;
    }

    &-handle {
      border: 2px solid $purple;
      margin-top: -4px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
    }
  }
}
