@import '../../palette';

.__public-player {

  max-width: 98%;
  width: 800px;
  margin: 20px auto;
  padding-top: 12px;
  text-align: center;

  .__loading-blocks .loading-moods {
    width: 80%;
  }

  .load-error {
    margin: 52px 0;
    border: 2px dashed $dark_red;
    padding: 32px 16px;
    border-radius: 4px;
  }

  .track-title {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }

  .like-count {
    display: flex;
    justify-content: center;
    user-select: none;

    > svg {
      width: 24px;
      margin-inline-end: 8px;

      &.active {
        > path {
          fill: $lime;
        }
      }
    }
  }

  .comments-disabled {
    font-style: italic;
    margin: 40px auto;
    color: $light_gray;
  }

  .cta-text {
    font-size: 120%;
  }

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    background-color: $yellow;
    border: none;
    border-radius: 32px;
    padding: 0px 12px;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: bold;
    font-family: 'Poiret One', sans-serif;
    color: $green;
    letter-spacing: 5px;
    cursor: pointer;
    transition: 0.3s all;
    width: 100%;
    text-decoration: none;

    &:hover {
      background-color: $light_yellow;
      transition: 0.3s all;
    }
  }

  .rc-slider-mark-text {
    color: $white;
  }

  .rc-slider.playback {
    margin-top: 16px;
    margin-bottom: 40px;

    &:hover {
      cursor: pointer;
    }

    .rc-slider {
      
      &-handle {
        visibility: hidden;
      }

      &-rail {
        height: 8px;
      }

      &-track {
        background-color: $lime;
        height: 8px;
      }

      &-step {

        .rc-slider-dot {
          bottom: inherit;
          border-color: #75b0ff;

          &:first-of-type, &:last-of-type {
            opacity: 0;
          }

          &-active {
            border-color: #2e84f5;
          }
        }
      }
    }
  }
}
