@import 'node_modules/include-media/dist/_include-media.scss';
@import '../palette';

.__top-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.__notification-bar {
  height: 60px;
  background-color: $lime;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  font-size: 14px;

  a {
    color: $purple;

    &:hover {
      color: $purple
    }
  }

  .__countdown-timer {
    display: inline-flex;

    .time-unit-container {
      margin-inline-end: 4px;
    }
  }

  .close-button {
    position: absolute;
    right: 8px;
    font-size: 36px;
    line-height: 28px;
    color: $purple;
  }
}

.__navigation-header {
  // position: fixed;
  // top: 0;
  // left: 0;
  display: flex;
  width: 100%;
  background-color: $red;
  padding: 8px 16px;
  justify-content: space-between;
  box-shadow: 0 0 5px 3px rgba(black, 0.3);
  align-items: center;
  height: 60px;

  .logo {
    height: 80%;
    fill: $white;
    margin-inline-end: 32px;
  }

  .link {
    font-family: $fredoka;
    display: flex;
    align-items: center;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-decoration: none;
    transition: 0.3s;

    &:not(:last-of-type) {
      margin-inline-end: 28px;
    }

    > svg {
      fill: $white;
      margin-right: 4px;
    }

    &:hover, &.active {
      color: $light_yellow;

      > svg {
        fill: $light_yellow;
      }
    }
  }

  .sale-badge {
    border-radius: 32px;
    padding: 2px 8px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.8rem;
    color: $red;
    background-color: $white;
    
    &:hover {
      text-decoration: none;
      background-color: $light_yellow;
    }
  }

  &-desktop {
    display: flex;
    justify-content: space-between;
    flex: 1;

    @include media("<=720px") {
      display: none;
    }

    .section {
      display: flex;
      align-items: center;
    }
  }

  &-mobile {
    display: flex;

    @include media(">720px") {
      display: none;
    }

    .hamburger-toggle {
      fill: $white;
    }

  }

  .mobile-menu-background {
    
    position: fixed;
    background-color: rgba(black, 0.3);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: none;
    opacity: 0;

    &.show-menu {
      
      &-enter {
        display: block;

        .mobile-menu {
          transform: translateX(100%);
        }

        &-active, &-done {
          display: block;
          opacity: 1;
          transition: 0.2s;

          @include media(">720px") {
            display: none;
          }      

          .mobile-menu {
            transform: translateX(0);
            transition: 0.2s;
          }
        }
      }

      &-exit {
        display: block;
        opacity: 1;

        .mobile-menu {
          transform: translateX(0);
          transition: 0.2s;
        }

        &-active {
          opacity: 0;
          transition: 1s;

          .mobile-menu {
            transform: translateX(100%);
            transition: 0.2s;
          }
        }
      }
    }

    .mobile-menu {
      padding: 16px 24px;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      width: 80%;
      min-width: 280px;
      z-index: 1000;
      background-color: $red;
      box-shadow: 0 0 12px 8px rgba(black, 0.3);
  
      .close-menu {
        font-size: 36px;
        font-weight: bold;
        text-align: right;
        line-height: 24px;
      }
  
      .link {
        margin-bottom: 12px;
        margin-inline-end: 0;
        font-size: 22px;

        &:first-of-type {
          margin-top: 20px;
        }
  
        > svg {
          margin-inline-end: 8px;
        }
      }

      .coin-balance {
        display: flex;
        justify-content: space-between;
        margin: 60px 0 20px;
        border-bottom: 2px solid $light_yellow;
        padding-bottom: 12px;
        font-weight: bold;
        font-size: 1.1em;
        
        .amount {
          display: flex;
          align-items: center;

          *:not(:last-child) {
            margin-inline-end: 8px;
          }      

          > svg {
            transform: scale(0.9);
          }

          .number {
            font-variant-numeric: tabular-nums;
          }      
        }

      }

      .coin-link {
        display: block;
        font-weight: bold;
        color: $light_yellow;
        margin: 12px 0;

        &.active {
          color: $white;
        }
      }

      .mobile-signup {
        text-align: center;
      }
    }
  }

  .coin-container {
    display: flex;

    .extra-coins {
      display: flex;
      align-items: center;
      visibility: hidden;
      margin-inline-end: -8px;

      .add-coins {
        background-color: $light_purple;
        font-weight: bold;
        border-radius: 4px;
        padding: 2px 6px;
        font-size: 0.8rem;
      }
  
      .coin-arrow {
        border-left: 7px solid $light_purple;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      }

      &.popin {
        &-enter {
          visibility: visible;
          transform: translateX(6px);
          opacity: 0.3;

          &-active, &-done {
            visibility: visible;
            opacity: 1;
            transform: translateX(0);
            transition: 0.3s ;
          }
        }
      }
    }

    .coin-menu {

      .dropdown {
        &.show .coin-toggle {
          background-color: $dark_red;
        }
        .dropdown-menu {
          font-size: 0.9em;
    
          .dropdown-item.active, .dropdown-item:active {
            background-color: $light_orange;
          }
        }   
      }

      &.highlight {
        &-enter {
          transform: scale(1.1);

          &-active, &-done {
            transform: scale(1);
            transition: 0.3s all;
          }
        }
      }
    }
  }

  .coin-toggle {
    font-weight: bold;
    font-size: 18px;
    border-radius: 32px;
    padding: 4px 12px;
    margin-inline-end: 12px;
    display: flex;
    align-items: center;
    user-select: none;

    &:hover {
      background-color: $dark_red;
      transition: 0.3s;
    }

    *:not(:last-child) {
      margin-inline-end: 6px;
    }

    > svg {
      transform: scale(0.9);
    }

    .number {
      font-variant-numeric: tabular-nums;
    }
  }

  button.sign-up {
    border-radius: 24px;
    font-weight: bold;
    font-size: 0.9rem;
    background-color: $red;
    color: $white;
    border: 2px solid rgba($white, 0.8);
  }
  
  .super-user {
    &-container {
      display: flex;
      align-items: center;
      margin-inline-end: 12px;

      .dropdown.show {
        .super-user-toggle {
          .__spirited-icon-st3 {
            fill: $white;
            transition: 0.3s;
          }
        }
      }

      .open-feedback-messages {
        position: relative;
        top: -13px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $olive;
        border-radius: 100%;
        width: 20px;
        height: 20px;
        font-weight: bold;
        font-size: 10px;
      }

      .support-center-link{
        display: flex;
        justify-content: space-between;

        .open-feedback-messages-counter {
          border-radius: 100%;
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $olive;
          color: $white;
          font-weight: bold;
          font-size: 12px;
        }
      }
    }

    &-toggle {
      .__spirited-icon-st6 {
        fill: $red;
      }

      .__spirited-icon-st2 {
        stroke: $red;
      }

      .__spirited-icon-st1 {
        stroke: $red;
      }

      .__spirited-icon-st3 {
        fill: $yellow;
      }

      &:hover {
        .__spirited-icon-st3 {
          fill: $white;
          transition: 0.3s;
        }
      }
    }
  }
}
