@import '../../palette';

.__drum-select {
  display: flex;
  align-items: center;
  background-color: $green;
  padding: 0 8px 0 2px;
  gap: 6px;

  .drag-handle {
    padding: 0 4px;
    cursor: grab;

    > svg {
      width: 6px;
      height: auto;
    }
  }

  &.dragging {
    background-color: $green;
    border-color: 1px solid rgba(172, 172, 172, 0.8);
    z-index: 100;
    box-shadow: 4px 4px 8px 2px rgba(0, 0, 0, 0.25);
  }

  .form-control-sm {
    padding: 0 4px;
    height: 20px;
    font-size: 13px;
    border-radius: 32px;
    border: none;
    text-transform: capitalize;
  }

  .delete-instrument {        
    font-weight: 500;
    font-size: 18px;
    line-height: 12px;
  }
}