@import '../../palette';

.__payment-modal {

  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $orange;
    font-size: 18px;
    font-weight: bold;
    
    .cost {
      white-space: nowrap;
    }

    border-bottom: 1px solid $light_gray;
    padding-bottom: 16px;
    margin-bottom: 24px;
  }

  .third-party-payments {
    margin-top: 10px;

    .separator {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid $light_gray;
      line-height: 0.1em;
      margin: 10px 0 20px;
      margin-top: 30px;

      span {
        background-color: #fff; 
        padding: 0 10px;
      }
    }
  }

  .card-number {
    padding: 12px;
    margin: 8px 0;
    // margin-bottom: 0;
    border-radius: 4px;
    // border-top-left-radius: 4px;
    // border-top-right-radius: 4px;
    border: 1px solid $input_border;
  }

  .date-cvc-container {
    display: flex;
    margin: 8px 0;
    // margin-top: 0;

    .expiry-date {
      flex: 2;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      border: 1px solid $input_border;
      border-right: none;
      padding: 12px;
    }

    .cvc {
      flex: 1;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border: 1px solid $input_border;
      padding: 12px;
    }
  }

  .paypal-container {
    width: 100%;
  }

  .pay-with-paypal {
    display: flex;
    gap: 12px;
    margin: 8px auto 16px;
    font-style: italic;
    color: $dark_gray;

    &:before, &:after {
      content: "";
      flex: 1;
      border-bottom: 1px solid $light_gray;
      margin: auto;
    }
  }
}