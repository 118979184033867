@import '../../palette';

.__user-stats {
  max-width: 800px;
  margin: 0 auto;
  padding: 12px;

  h1 {
    text-align: center;
  }

  h2 {
    margin-top: 48px;
    border-bottom: 1px solid $yellow;
    padding-bottom: 8px;
    color: $yellow;
  }

  .loading {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-count{
    text-align: center;
    font-size: 50px;
  }

  .stats-header {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      background-color: $orange;
      height: 50px;
      font-size: 20px;
    }

    :first-child {
      border-radius: 15px 0 0 15px;
    }
    :last-child {
      border-radius: 0 15px 15px 0;
    }
  }

  .stats-row {
    display: flex;
    justify-content: center;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 50px;
      font-size: 20px;
      border-bottom: 1px solid $white;
    }
  }
}
