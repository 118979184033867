@import '../../palette';

.__instrument-selector-modal {

  &.__instrument-selector-modal-drums {
    .modal-body {
      height: unset;
    }
  }

  .modal-dialog {
    max-width: 668px;
  }

  .modal-header {
    border: none;

    .search {
      margin: -0.5rem 0 0 auto;
      width: 200px;
      border-radius: 32px;
      height: 32px;
      background-color: transparent;
      border-color: 1px solid white;
      color: white;

      &::placeholder {
        color: $light_gray;
      }
    }

    .close {
      color: $dark_green;
      background-color: white;
      border-radius: 100%;
      padding: 0 6px;
      font-size: 30px;
      display: flex;
      margin: -0.5rem -0.5rem -1rem 1rem;

      &:hover {
        color: $green;
      }
    }
  }

  .modal-body {
    height: 70vh;
    overflow: auto;
  }

  .modal-footer {
    border: none;
  }

  .modal-content {
    background-color: $dark_green;
    color: white;
    border-color: white;

    h3.category-title {
      border-bottom: 1px solid white;
      user-select: none;
    }

    h3.search-result {
      margin: 32px 0;
      font-size: 22px;
      text-align: center;
      font-style: italic;
    }

    .hide-categories {
      display: none;
    }

    .instrument-category {
      display: flex;
      margin: 20px 0 32px;
      gap: 32px;
      align-items: center;  
    }

    .left-arrow-container, .right-arrow-container {
      width: 48px;

      .arrow-icon {
        background-color: white;
        // padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        width: 32px;
        border-radius: 100%;
        margin-bottom: 20px;

        &.disabled {
          background-color: $gray;
        }

        > svg {
          width: 16px;

          > path {
            stroke: $dark_green;
            fill: $dark_green;
          }
        }
      }
    }

    .left-arrow-container {
      .arrow-icon > svg {
        transform: rotate(180deg);
      }
    }

    .instruments-overflow-container {
      display: flex;
      gap: 12px;
      overflow: hidden;
    }
  }

  .instrument-search {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;

    .instrument-icon {
      width: 88px;
    }
  }

  .drums {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    margin: 32px 32px 0;

    .instrument-icon {
      width: 88px;
    }
  }

  .instrument-icon {
    text-align: center;
    font-size: 11px;
    transition: 0.3s transform;
    padding: 8px;
    border-radius: 8px;
    margin-top: 4px;
    user-select: none;

    > svg {
      width: 72px;
      height: 72px;
    }

    &:hover {
      transform: translateY(-4px);
    }

    &.selected {
      background-color: $green;
      filter: saturate(1.2) brightness(1.1);
    }
  }
}