@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__potential-investor-stats {
  width: 800px;
  max-width: 96%;
  margin: 96px auto 0;

  h1.header {
    font-family: $fredoka;
    margin: 0px auto 40px;
    text-transform: uppercase;
    font-size: 2.2rem;
    letter-spacing: 1px;
    text-align: center;
  }

  .loading {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .investor {
    display: flex;
    .id {
      width: 50px;
      text-align: center;
    }

    .email {
      width: 700px;
    }
  }
}
