@import 'node_modules/include-media/dist/_include-media.scss';
@import '../palette';

.__hero-sale {
  position: relative;
  width: 100%;

  .close-banner {
    position: absolute;
    top: 8px;
    right: 12px;
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    padding: 0 8px;
    z-index: 2;

    &.dark-color {
      color: $purple;
    }
  }
  
  .try-anime {
    padding: 1vw 2vw;
    background-color: $dark_red;

    a {
      text-decoration: none;
      transition: 0.3s color;
    }

    &-inner {
      max-width: 980px;
      display: flex;
      margin: 0 auto;
      align-items: center;
      gap: clamp(2px, 2vw, 30px);

      .animated-icon {
        position: relative;
        width: 50%;

        .anime-icon {
          width: 100%;
          height: auto;

          .__anime-icon-0 {
            fill: transparent;
          }
        }
  
        .animated-star {
          position: absolute;
          animation: animation-hero-sale-anime-icon 2.6s infinite;

          .__star-icon-outline {
            fill: white;
            stroke: white;
            stroke-width: 1;
          }

          &-left {
            width: 25%;
            top: 35%;
            left: 1%;
          }
  
          &-top-right {
            width: 16%;
            top: 8%;
            right: 6%;
            animation-delay: 0.3s;
          }

          &-center-right {
            width: 10%;
            top: 35%;
            right: 5%;
            animation-delay: 0.6s;
          }
        }
      }
      
      .title-container {
        position: relative;

        h2 {
          font-family: $bangers;
          font-size: clamp(24px, 12vw, 120px);
        }

        h2.title-top {
          z-index: 2;
          position: relative;
        }
  
        h2.title-shadow {
          position: absolute;
          top: 2%;
          left: 1%;
          color: $purple;
          z-index: 1;
        }
      }

    }

  }

  .animay-sale {
    background-color: $lime;
    position: relative;

    a {
      transition: 0.3s filter;
      text-decoration: none;
      
      &:hover {
        filter: saturate(1.1) brightness(1.1);
      }
    }


    &-inner {
      padding: 1vw 0;
      display: flex;
      align-items: center;
      
      @include media("<=1080px") {
        justify-content: center;
      }

      @include media("<=640px") {
        display: block;
        padding-top: 32px;
      }

      .avatar-cloud-trail {
        flex: 1;
        align-self: start;

        @include media("<=1080px") {
          display: none;
        }

        .cloud-trail {
          width: 100%;
          height: 102px;
          border-top: 1.5px solid black;
          border-bottom: 1.5px solid black;
          background-color: white;
          margin-top: 198px;

          font-family: $fredoka;
          font-size: 26px;
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          a {
            display: block;
            color: $purple;
            transition: 0.3s color;

            &:hover {
              color: $red;
            }
          }

          .text {
            max-width: 200px;
            margin-left: auto;
            margin-right: 20px;
          }
        }
      }

      .right-column {
        flex: 1;

        @include media("<=1240px") {
          flex: 0.3;
        }

        @include media("<=1080px") {
          display: none;
        }
      }

      .sale-content {
        display: flex;
        align-items: center;
        margin: 20px 0;

        .sale-copy {
          width: 612px;
          margin-left: -40px;

          @include media("<=640px") {
            width: 96%;
            margin: 0;
          }
        }
      }

      .sale-avatar {
        width: 220px;
        height: auto;
        transform: translateX(-15%);

        @include media("<=1080px") {
          display: none;
        }
      }
  
      .title-container {
        position: relative;
        text-align: right;

        @include media("<=640px") {
          text-align: center;
        }

        h2 {
          font-family: $bangers;
          font-size: 142px;
          line-height: 124px;
          text-stroke: 2px black;
          -webkit-text-stroke: 2px black;
          color: $orange;
          position: relative;
          z-index: 2;

          @include media("<=640px") {
            font-size: 20vw;
            line-height: 20vw;
            width: 100%;
          }
  
          &.title-shadow {
            position: absolute;
            z-index: 1;
            color: $purple;
  
            top: 6%;
            right: -0.5%;
          }
        }
      }

      .subtitle {
        font-family: $bangers;
        font-size: 86px;
        line-height: 86px;
        color: $red;
        text-stroke: 2px black;
        -webkit-text-stroke: 2px black;
        text-align: right;
        margin-bottom: 12px;

        @include media("<=640px") {
          font-size: 12vw;
          line-height: 12vw;
          text-align: center;
        }

        .percent-off {
          display: inline-flex;
          font-size: 48px;
          flex-direction: column;
          line-height: 36px;
          vertical-align: super;
          text-align: center;
          margin: 0 8px;

          @include media("<=640px") {
            font-size: 8vw;
            line-height: 6vw;
            margin: 0 4px;
          }

          .off {
            font-size: 32px;
            font-family: $roboto;
            font-weight: bold;

            @include media("<=640px") {
              font-size: 6vw;
            }
          }
        }
      }

      .offer-ends {
        color: $dark_gray;
        text-align: right;
      }
    }
  }
}

@keyframes animation-hero-sale-anime-icon {
  0% {
    opacity: 0;
    transform: rotate(15deg) scale(0.6);
  }

  3% {
    opacity: 0.3;
    transform: rotate(15deg) scale(1.7);
  }

  50% {
    opacity: 1;
    transform: translateY(-30%) rotate(10deg);
  }

  80% {
    opacity: 1;
    transform: translateY(-30%) rotate(10deg);
  }

  100% {
    opacity: 0;
    transform: translateY(-30%) rotate(10deg);
  }
}
