@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__song-player-multitrack {
  display: block;

  .title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 32px auto;

    @include media("<=720px") {
      flex-direction: column;
      margin: 20px auto;

      .__editor-equalizer {
        display: none;
      }

      .track-share {
        margin-top: 28px;
        margin-left: auto;
      }
    }

    .__editor-equalizer {
      margin-right: 16px;
    }

    .title {
      display: flex;
      align-items: center;
    }
  }

  .song-title-container {
    // margin: 16px auto;
    display: flex;
    align-items: center;
    position: relative;
    
    input {
      border-radius: 32px;
      font-weight: bold;
      font-size: 1.2rem;
      background-color: transparent;
      border-color: transparent;
      color: $white;
      padding-right: 44px;

      &:hover, &:focus {
        border-color: rgba($olive, 0.5);

        + svg {
          visibility: visible;
        }
      }

      &:focus {
        border-color: $olive;

        + svg {
          transition: 0.3s;
          fill: $yellow;
        }
      }
    }

    > svg {
      margin-left: 8px;
      fill: $light_olive;
      height: 20px;
      width: 20px;
      position: absolute;
      right: 16px;
      visibility: hidden;
      pointer-events: none;

      @include media("<=720px") {
        visibility: visible;
      }
    }

  }

  .track-share {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  .share-button {
    background-color: $red;
    color: $white;
    border: none;
    font-weight: bold;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 32px;

    &:hover:not(:disabled) {
      background-color: $orange;
      transition: 0.5s all;
    }
  }

  .mobile-song-player {
    display: none;

    @include media("<=720px") {
      display: block;
    }
  }

  pre {
    color: $white;
  }

  .rc-slider-mark-text {
    color: $white;
  }
}

.__song-player {
  max-width: 98%;
  margin: 0 auto;
  margin-top: 48px;
  padding: 20px;
  text-align: center;

  .loading-track {
    margin: 48px auto;
    text-align: center;
  }

  .load-error {
    margin: 52px auto;
    border: 2px dashed $dark_red;
    padding: 60px 16px;
    border-radius: 4px;
    text-align: center;
    max-width: 420px;
    line-height: 2.4rem;
  }

  @include media("<=720px") {
    max-width: 400px;
  }

  .rc-slider.playback {
    margin-top: 16px;
    margin-bottom: 40px;


    &:hover {
      cursor: pointer;
    }

    .rc-slider {
      
      &-handle {
        visibility: hidden;
      }

      &-rail {
        height: 8px;
      }

      &-track {
        background-color: $lime;
        height: 8px;
      }

      &-step {

        .rc-slider-dot {
          bottom: inherit;
          border-color: $red;

          &:first-of-type, &:last-of-type {
            opacity: 0;
          }

          &-active {
            border-color: $red;
          }
        }
      }
    }
  }

  .download-button {

    @include media("<=720px") {
      width: 100%;
    }

    &:disabled {
      color: $white;
      background-color: $light_gray;
    }
    
    background-color: $orange;
    box-shadow: 2px 2px 2px 0 rgba(black, 0.1);
    color: white;
    border: none;
    border-radius: 32px;
    font-family: inherit;
    display: block;
    margin: 20px auto;
    padding: 4px 16px;
    width: 320px;
    font-size: 28px;
    font-weight: 500;
    text-align: center;

    &:hover:not(:disabled) {
      background-color: $light_orange;
      transition: 0.5s all;
      text-decoration: none;
    }
  }

  .regenerate-song {
    @include media("<=720px") {
      width: 100%;
    }

    background-color: $light_purple;
    box-shadow: 2px 2px 2px 0 rgba(black, 0.1);
    color: white;
    border: none;
    border-radius: 32px;
    font-family: inherit;
    display: block;
    margin: 20px auto;
    padding: 4px 16px;
    width: 320px;
    font-size: 24px;
    font-weight: 500;
    text-align: center;

    &:hover:not(:disabled) {
      background-color: $purple;
      transition: 0.5s all;
      text-decoration: none;
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;

      .reroll-icon {
        width: 48px;
        height: 48px;
        margin-inline-end: 12px;
      }
    }
  }
}