@import '../palette';

.__login-modal, .__registration-modal {
  .logging-in {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: $dark_gray;

    .__infinite-loader {
      margin-bottom: 12px;
    }
  }

  .form-hidden {
    visibility: hidden;
  }
}
