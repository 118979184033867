@import '../../palette';

$lib-eq-bar-height-1: 20px;
$lib-eq-bar-height-2: 16px;
$lib-eq-bar-height-3: 14px;
$lib-eq-bar-height-4: 10px;
$lib-eq-bar-height-5: 4px;
$lib-eq-bar-height-6: 2px;

.__library-equalizer {
  position: relative;

  width: 20px;
  height: 20px;

  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  gap: 1px;

  .bar {
    display: block;
    width: 3px;
    background-color: $lime;
    height: 20px;
    border-radius: 4px;
    animation: animation-lib-equalize 8s 0s infinite;
    animation-play-state: running;
  }
}

// Inspiration: https://codepen.io/gauldo/pen/MdOoOZ
@keyframes animation-lib-equalize {
  0% {
    height: $lib-eq-bar-height-1;
  }
  4% {
    height: $lib-eq-bar-height-2;
  }
  8% {
    height: $lib-eq-bar-height-3;
  }
  12% {
    height: $lib-eq-bar-height-4;
  }
  16% {
    height: $lib-eq-bar-height-5;
  }
  20% {
    height: $lib-eq-bar-height-4;
  }
  24% {
    height: $lib-eq-bar-height-3;
  }
  28% {
    height: $lib-eq-bar-height-6;
  }
  32% {
    height: $lib-eq-bar-height-3;
  }
  36% {
    height: $lib-eq-bar-height-1;
  }
  40% {
    height: $lib-eq-bar-height-5;
  }
  44% {
    height: $lib-eq-bar-height-3;
  }
  48% {
    height: $lib-eq-bar-height-1;
  }
  52% {
    height: $lib-eq-bar-height-4;
  }
  56% {
    height: $lib-eq-bar-height-6;
  }
  60% {
    height: $lib-eq-bar-height-4;
  }
  64% {
    height: $lib-eq-bar-height-1;
  }
  68% {
    height: $lib-eq-bar-height-4;
  }
  72% {
    height: $lib-eq-bar-height-1;
  }
  76% {
    height: $lib-eq-bar-height-5;
  }
  80% {
    height: $lib-eq-bar-height-1;
  }
  84% {
    height: $lib-eq-bar-height-6;
  }
  88% {
    height: $lib-eq-bar-height-1;
  }
  92% {
    height: $lib-eq-bar-height-5;
  }
  96% {
    height: $lib-eq-bar-height-1;
  }
  100% {
    height: $lib-eq-bar-height-1;
  }
}