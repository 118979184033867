@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__composer {
  max-width: 98%;
  margin: 0 auto;
  padding-top: 32px;

  .loading {
    text-align: center;
  }

  .style-controls {
    display: flex;
    gap: 20px;
    align-items: flex-start;

    .style-select {
      padding: 12px 16px 0;
      background-color: $green;
      width: 120px;
      border-radius: 28px;
      cursor: pointer;
      transition: 0.3s background-color;

      &:hover {
        background-color: $medium_light_green;
      }

      .style-name {
        text-align: center;
        font-size: 14px;
      }
    }

    .save-container {
      padding: 14px;
      background-color: $green;
      width: 120px;
      border-radius: 24px;
      margin: 20px 0;
      text-align: center;

      button {
        border: none;
        border-radius: 12px;
        padding: 4px 20px;
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;
        background-color: $olive;
        color: white;
        transition: 0.3s background-color;

        &:hover {
          background-color: $light_olive;
        }
      }
    }
  }

  .main-editor {
    width: 1120px;
    background-color: $dark_green;
    margin: 0 auto;
    padding: 1px 80px 32px 80px;
    border-radius: 60px;

    .title-edit {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .sequence-status {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 12px;
        width: auto;
        color: white;
        border: none;

        &.draft {
          background-color: $purple;
        }

        &.ready {
          background-color: $orange;
        }

        &.review {
          background-color: $red;
        }

        &.published {
          background-color: $olive;
        }

      }
    }

    .song-title-container {
      margin: 16px 0;
      display: flex;
      align-items: center;
      position: relative;
      max-width: 420px;
      
      input {
        border-radius: 32px;
        font-weight: bold;
        font-size: 1.2rem;
        background-color: transparent;
        border-color: transparent;
        color: $white;
        padding-right: 44px;
  
        &:hover, &:focus {
          border-color: rgba($olive, 0.5);
  
          + svg {
            visibility: visible;
          }
        }
  
        &:focus {
          border-color: $olive;
  
          + svg {
            transition: 0.3s;
            fill: $yellow;
          }
        }
      }
  
      > svg {
        margin-left: 8px;
        fill: $light_olive;
        height: 20px;
        width: 20px;
        position: absolute;
        right: 16px;
        visibility: hidden;
        pointer-events: none;
      }
    }  
  }

  .controls {
    flex: 1;

    .main-controls {
      background-color: $green;
      padding: 14px 20px;
      border-radius: 28px;
  
      display: flex;
      flex: 1;
      align-items: center;
      gap: 16px;
  
      .duration-slider {
        display: flex;
        width: 228px;
        border-radius: 20px;
        border: 2px solid white;
        padding: 4px 12px;
        font-weight: bold;
        color: white;
        flex: 1;
        background-color: $orange;
        align-items: center;
        gap: 8px;

        .amount {
          min-width: 16px;
          text-align: center;
        }

        .rc-slider {
          &-track {
            background-color: $purple;
          }

          &-handle {
            border-color: $gray;
          }
        }
      }

      .dynamic-range {
        display: flex;
        width: 200px;
        border-radius: 20px;
        border: 2px solid white;
        padding: 4px 12px;
        font-weight: bold;
        color: white;
        flex: 1;
        background-color: $red;
        align-items: center;
        gap: 8px;

        .amount {
          min-width: 8px;
          text-align: center;
        }

        .rc-slider {
          margin: 0 4px;

          &-handle-dragging {
            box-shadow: none;
          }

          &-track {
            background-color: $purple;
          }

          &-handle {
            border-color: $gray;
          }
        }
      }
  
      .option {
        border-radius: 20px;
        border: 2px solid white;
        padding: 4px 8px;
        font-weight: bold;
        color: white;
        width: 192px;
        text-overflow: ellipsis;
        white-space: nowrap;
  
        &.scale {
          background-color: $purple;
        }
  
        &.root {
          background-color: $olive;
        }
  
        .__checkbox {
          margin: 4px 0;
          justify-content: space-between;

          .__checkbox-toggle > input:checked + .__checkbox-slider {
            background-color: $lime;
          }

          &-toggle {
            height: 18px;
          }

          &-slider {
            &::before {
              background-color: $purple;
              border: 1px solid white;
              top: -1px;
            }
          }
        }
  
        &.pentatonic {
          background-color: $red;
        }
  
        &.over-the-bar {
          background-color: $orange;
        }
      }
  
      .selector {
        font-weight: bold;
        display: flex;
        gap: 12px;
        align-items: center;
  
        &:first-child {
          margin-bottom: 16px;
        }
  
        .label {
          min-width: 44px;

          &.range {
            width: 72px;
            text-align: center;
          }
        }
      }
    }

    .tempo-swing {
      margin: 20px 0 20px;
      background-color: $green;
      padding: 12px 56px;
      border-radius: 24px;
      display: flex;
      gap: 16px;
      font-weight: bold;
      align-items: center;

      .playback-toggle {
        width: 80px;
        height: 32px;
        background-color: $yellow;
        border-radius: 12px;
        margin-right: 20px;
        border: 1px solid $red;
        box-shadow: 0 0 0 2px $orange;
        display: flex;
        padding: 9px;
        align-items: center;
        justify-content: center;
        transition: 0.3s;
        user-select: none;
  
        > svg {
          fill: $white;
          width: 14px;
        }
  
        &:hover {
          transform: scale(1.05);
        }

        .stop-icon {
          width: 14px;
          height: 14px;
          background-color: $white;
        }
      }

      .slider-container {
        display: flex;
        border-radius: 20px;
        border: 2px solid white;
        padding: 4px 12px;
        font-weight: bold;
        color: white;
        flex: 1;
        background-color: $purple;
        align-items: center;
        gap: 8px;
        width: 100px;

        .number {
          min-width: 32px;
          text-align: center;
        }

        .rc-slider {
          &-track {
            background-color: $lime;
          }

          &-handle {
            border-color: $gray;
          }
        }
      }
    }
  }

  .velocity-editor {
    display: flex;
    gap: 20px;
    height: 600px;
  }

  .velocity-probability {
    border-radius: 28px;
    background-color: $green;
    width: 120px;
  }

  .velocity-slider {
    text-align: center;
    padding: 20px;
    height: 320px;

    .rc-slider {
      margin: 2px auto;

      &-track {
        left: 3px;
        width: 8px;
        
        background-color: $lime;
        border: 1px solid $purple;
      }

      &-handle {
        border-color: $purple;
      }

      &-rail {
        left: 3px;
        width: 8px;

        border: 1px solid $purple;
      }
    }
  }
  
  .probability {
    margin: 100px 6px 0;

    .label {
      text-align: center;
      margin-bottom: 4px;
      font-weight: bold;
    }

    &-slider {
      display: flex;
      align-items: center;

      .amount {
        min-width: 34px;
        text-align: center;
      }  
      
      .rc-slider {
        margin-right: 8px;
        height: 20px;

        &-track {
          height: 10px;
          background-color: $orange;
          border: 1px solid $purple;
        }
  
        &-handle {
          margin-top: -3px;
          width: 16px;
          height: 16px;
          border-color: $purple;
        }
  
        &-rail {
          height: 10px;
          border: 1px solid $purple;
        }
      }
    }
  }

  .editor {
    overflow: auto;
    border-radius: 28px;
    background-color: $green;
    flex: 1;

    .roll-controls {
      display: flex;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 12;
      gap: 20px;
      font-weight: bold;
      align-items: center;
      margin-bottom: 20px;
      background-color: $green;
      padding: 20px 20px 16px;

      .form-label {
        margin-bottom: 0;
      }

      select {
        border-radius: 20px;
        border: 2px solid white;
        padding: 4px 8px;
        font-weight: bold;
        color: white;

        &.sub-division {
          width: 80px;
          background-color: $orange;
        }
  
        &.program-select {
          width: 240px;
          background-color: $purple;
        }
      }


      .note-chord-checkbox {
        display: flex;
        border-radius: 20px;
        border: 2px solid white;
        padding: 0 12px;
        align-items: center;
        gap: 12px;

        .__checkbox {
          &-slider {
            background-color: $orange;

            &::before {
              background-color: $purple;
              border: 2px solid white;
              width: 24px;
              height: 24px;
              left: -2px;
              top: -2px;
            }
          }
        }
      }
    }

    .overflow-margin-bottom {
      position: sticky;
      z-index: 20;
      overflow: hidden;
      display: flex;
      background-color: $green;
      left: 0;
      bottom: 0;
      height: 16px;
    }
  }

  h1 {
    font-family: $fredoka;
    margin: 0px auto 40px;
    text-transform: uppercase;
    font-size: 2.2rem;
    letter-spacing: 1px;
    text-align: center;
  }
}
