@import '../palette';

.__private-route {
  &-footer {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    margin-bottom: 16px;
    gap: 4px;

    a, .link {
      cursor: pointer;
      color: $light_yellow;
      
      &:hover {
        text-decoration: underline;
      }
    }

    > div {
      margin: 0 4px;
    }
  }
}

.__route {
  margin-top: 60px;
  
  &.__with-notification-bar {
    margin-top: 120px;
  }

  &.__no-header {
    margin-top: 0;
  }
}
