.__policies {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Roboto', 'Helvetica Neue', sans-serif;

  h2 {
    text-align: center;
    margin-bottom: 32px;
  }

  h3 {
    margin-top: 32px;
    margin-bottom: 20px;
  }

  a {
    text-decoration: underline;
  }

  .deletion {
    width: 100%;
  }
}