@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__missing-translations {
  width: 980px;
  max-width: 95%;
  margin: 20px auto;

  h1 {
    text-align: center;
    margin: 40px;
    font-family: $fredoka;
    text-transform: uppercase;
  }

  .coverage {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-bottom: 24px;
    align-items: center;
    
    .percent {
      background-color: $dark_red;
      padding: 4px 8px;
      font-size: 16px;
      border-radius: 8px;

      &.full-coverage {
        background-color: $lime;
        color: $dark_green;
      }
    }
  }

  table.phrases {
    margin-bottom: 32px;

    thead {
      border-bottom: 2px solid $white;
    }

    th {
      padding: 8px;

      &.key {
        width: 30%;
      }

      &.phrase {
        width: 70%;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: rgba($dark_green, 0.5);
          border-radius: 8px;
        }

        td {
          padding: 12px 8px;
        }
      }
    }
  }

  textarea {
    width: 100%;
    height: 400px;
    font-family: monospace;
    white-space: pre;
    font-size: 14px;
  }
}