@import '../../palette';
@import 'node_modules/include-media/dist/_include-media.scss';

.__download-track-flow {
  .price-detail {
    display: flex;
    justify-content: space-between;
    margin: 16px auto;
    color: $dark_gray;

    &:first-of-type {
      margin-top: 24px;
    }
    
    @include media("<=720px") {
      font-size: 0.9rem;
    }

    max-width: 380px;

    .amount {
      font-variant-numeric: tabular-nums;
      
      > svg {
        margin-inline-end: 6px;
      }
    }

    &.strong {
      font-weight: bold;
    }

    &.final-balance {
      border-top: 1px solid $lighter_gray;
      padding-top: 12px;
      font-weight: bold;
    }
  }

  .add-midi {

    &-container {
      text-align: center;
    }

    padding: 4px 16px;
    border-radius: 32px;
    font-size: 0.9rem;
    display: inline-block;
    border: 1px dashed $lighter_gray;

    .__checkbox-text {
      color: $purple;
    }
  }

  .oops-icon {
    margin: 0 auto 24px;
    width: 96px;
  }

  .add-midi-btn {
    color: $purple;
;
  }

  &.__modal {
    .download-footer.modal-footer {
      .btn {
        font-size: 1rem;
      }
      
      @include media("<=720px") {
        .btn {
          font-size: 0.8rem;
        }
        
        .mobile-hidden {
          display: none;
        }
      }

      .format {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: center;
        width: 100%;

        flex-wrap: wrap;
        
        .select-format {
          width: 100%;
          flex-shrink: 0;
          text-align: center;
          color: $dark_gray;
        }
      }
    }
  }
}