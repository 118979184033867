@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__library-player {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $dark_green;
  
  .controls {
    display: flex;
    margin: 0 auto;
    padding: 28px 0;
    width: 980px;
    max-width: 100%;

    @include media("<=720px") {
      flex-direction: column;
      padding: 8px;
    }

    @include media(">1366px") {
      width: 1160px;
    }

    .info {
      display: flex;
      align-items: center;
      // gap: 12px; @MB-130

      > *:not(:last-child) {
        margin-right: 12px; // @MB-130

        @include media("<=720px") {
          margin-right: 4px;
        }
      }

      .title {
        min-width: 120px;
        max-width: 240px;

        @include media(">1366px") {
          max-width: 360px;
        }    

        @include media("<=720px") {
          margin: 4px auto;
        }

        .song-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 18px;
          font-weight: bold;
        }

        .subtitle {
          font-style: italic;
          color: $light_gray;
          font-size: 0.8rem;
          display: none;

          @include media("<=720px") {
            display: block;
          }
        }
      }

      .__master-volume {
        width: 120px;
        margin-inline-start: 8px;
        margin-inline-end: 24px;

        @include media("<=720px") {
          display: none;
        }

        .rc-slider-handle {
          margin-top: -1px;
          height: 14px;
          width: 14px;
        }

        > svg {
          margin-left: 8px;
          width: 16px;
          height: 16px;
        }
      }

      .playback {

        display: flex;
        align-items: center;
        margin-inline-end: 16px;

        > *:not(:last-child) {
          margin-right: 8px; // @MB-130
        }

        @include media("<=720px") {
          flex: 1;
          justify-content: flex-end;
          margin-inline-end: 0;
        }

        .toggle-playback {
          display: flex;
          
          > svg {
            width: 16px;
            height: 16px;
            fill: $white;
          }
        }

        svg.change-track {
          width: 12px;
          height: 12px;
          fill: $white;
          stroke: $white;

          @include media("<=720px") {
            display: none;
          }  
        }

        svg {
          &:hover {
            fill: $lime;
            transform: scale(1.1);
            transition: 0.3s transform;
          }
        }
      }
    }

    .seek {
      flex: 1;
      display: flex;
      align-items: center;

      .marker {
        font-size: 12px;
        min-width: 32px;
        font-variant-numeric: tabular-nums; // prevents numbers from resizing (good for timers)
        user-select: none;

        &:first-of-type {
          text-align: right;
          margin-inline-end: 8px;
        }

        &:last-of-type {
          margin-inline-start: 8px;
        }
      }

      // because of a bug in rc-slider (the progress shows same when song changes, meaning
      // the cleanup with unmount is needed), a temporary slider is shown:
      .slider-filler {
        width: 100%;
        background-color: $white;
        height: 12px;
        border-radius: 12px;
      }

      .rc-slider {     
        padding-top: 1px;

        &-rail {
          height: 12px;
        }

        &-track {
          background-color: $orange;
          height: 12px;
        }

        &-handle {
          border: 2px solid $purple;
          margin-top: -1px;
          height: 14px;
          width: 14px;
          border-radius: 50%;
        }
      }
    }
  }

  .__mood-style-icon {
    > svg {
      width: 32px;
      height: 32px;
    }
  }
}
