@import 'node_modules/include-media/dist/_include-media.scss';
@import '../palette';

.__loading-blocks {
  margin: 0 auto 120px;
  min-height: 240px;
  animation: 1s anim-loading-blocks-container forwards;

  p.loading {
    font-weight: 500;
    color: $white;
    font-size: 24px;
  }

  .loading-moods {
    display: flex;
    width: 40%;
    max-width: 400px;
    margin: 0 auto;
    position: relative;

    @include media("<=720px") {
      width: 96%;
    }

    .mood-icon {
      transform: translateY(100px);
      padding: 4px;
      animation: anim-loading-blocks 1.5s infinite alternate;

      &:nth-of-type(2) {
        animation-delay: 0.25s;
      }

      &:nth-of-type(3) {
        animation-delay: 0.5s;
      }

      &:nth-of-type(4) {
        animation-delay: 0.75s;
      }

      &:nth-of-type(5) {
        animation-delay: 1s;
      }
    }
  }
}

@keyframes anim-loading-blocks-container {
  from {
    opacity: 0;
    transform: scale(1.15);
  }
  
  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes anim-loading-blocks {
  0%, 33% {
    transform: translateY(100px);
  }
  
  100% {
    transform: translateY(0);
  }
}
