@import '../../palette';
@import 'node_modules/include-media/dist/_include-media.scss';

.__select-style-modal {
  .modal-content {
    background-color: $dark_green;
  }

  .style-picker {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    align-items: center;
  }

  .style-button {
    text-align: center;
    font-size: 20px;
    flex: 1;
    margin: 4px;
    border: 1px solid transparent;
    color: $white;
    
    &.mood {
      font-weight: bold;

      &:hover {
        background-color: rgba(white, 0.1);
      }

      &.active {
        background-color: rgba(white, 0.1);
        border-color: transparent;
      }
    }

    &.genre {
      font-size: 16px;
      flex-basis: 20%;
      max-width: 24%;
      border: none;
      // max-width: 112px;

      &:focus, &:active {
        box-shadow: none !important;
      }

      &.active {
        background-color: transparent;
        border: none;

        .hovered {
          opacity: 1;
        }

        .outline {
          opacity: 0;
        }
      }  

      &:active {
        box-shadow: none;
      }

      &:hover {
        background-color: rgba(white, 0.1);

        // .hovered {
        //   opacity: 1;
        //   transition: 0.3s;
        // }

        // .outline {
        //   opacity: 0;
        //   transition: 0.15s;
        // }
      }
      
      .icon-container {
        position: relative;
      }

      .hovered {
        opacity: 0;
      }

      .outline {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .genre-text {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 20px;
      }

      .text-bossa, .text-orc_pop, .text-retro_futuristic {
        font-size: 14px;
      }

      .anim-star {
        position: absolute;
        z-index: 10;
        animation: animation-anime-icon 2.6s infinite;

        .__star-icon-outline {
          fill: #cfd4d7;
          stroke: #fff;
          stroke-width: 1;
        }

        &-left {
          width: 24px;
          top: 40%;
          left: 2px;
        }

        &-top-right {
          width: 16px;
          top: 10%;
          right: 8%;
          animation-delay: 0.3s;
        }

        &-center-right {
          width: 10px;
          top: 35%;
          right: 5%;
          animation-delay: 0.6s;
        }
      }
    }
    
    @include media("<=720px") {
      flex-basis: 25%;
      max-width: 32%;
    }

    .icon {
      font-size: 48px;
      padding: 8px;
    }
  }
}
