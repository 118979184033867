@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__create-song-details {

  max-width: 400px;
  margin: 0 auto;

  .details {
    .create-song-button {
      font-family: $fredoka;
      text-transform: uppercase;
      background-color: $red;
      border: none;
      border-radius: 60px;
      display: flex;
      align-items: center;
      padding: 8px 24px;
      margin: 20px auto;
      font-size: 40px;
      color: white;
      cursor: pointer;
      transition: 0.5s all;
      max-width: 100%;
      justify-content: center;

      &:hover {
        background-color: $light_red;
        transition: 0.5s all;
        transform: scale(1.02);
      }

      &.button-disabled {
        pointer-events: none;
        opacity: 0.2;
      }

      .icon {
        margin-inline-end: 8px;
        display: flex;
        
        > svg {
          width: 36px;
          height: 36px;
          fill: white;
        }
      }
    }

    .new-song {
      text-align: center;
      display: block;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}
