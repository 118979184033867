@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__investor-invite-manager {
  width: 800px;
  max-width: 96%;
  margin: auto;
  padding-top: 32px;

  h1.header {
    font-family: $fredoka;
    margin: 0px auto 40px;
    text-transform: uppercase;
    font-size: 2.2rem;
    letter-spacing: 1px;
    text-align: center;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @include media("<=720px") {
      flex-direction: column-reverse;

      > button {
        margin-bottom: 12px;
      }
    }

    .filters {
      display: flex;
      align-items: center;
      gap: 20px;

      @include media("<=720px") {
        gap: 8px;
        
        .title {
          display: none;
        }
      }
    }
  }

  .investor-invite {
    background-color: $dark_green;
    border-radius: 8px;
    margin: 20px auto;
    padding: 12px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 4px 0 12px;
      padding-bottom: 8px;
      border-bottom: 2px solid $green;

      @include media("<=720px") {
        display: block;
     }

      h3 {
        font-size: 1.3em;
        margin: 0;

        @include media("<=720px") {
          margin-bottom: 12px;
        }
      }

      .coin-controls {
        display: flex;
        align-items: center;
        gap: 16px;

        @include media("<=720px") {
          justify-content: flex-end;
        }
        
        .amount {
          background-color: $green;
          padding: 4px 16px;
          border-radius: 16px;
          display: flex;
          align-items: center;
          gap: 8px;

          > svg {
            width: 20px;
            height: 20px;
          }
        }
      }     
    }

    input.permalink-preview {
      font-size: 0.9rem;
      margin-bottom: 1rem;
      border-radius: 20px;
      background-color: rgba($green, 0.5);
      color: $white;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-color: transparent;
    }  

    .options {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 8px;
      align-items: center;

      .valid-until {
        flex: 1;
        font-style: italic;
      }
    }
  }

  .empty-investor-invites {
    border: 3px dashed $dark_red;
    padding: 60px 16px;
    border-radius: 4px;
    text-align: center;
    line-height: 2.4rem;
    font-size: 1.1em;
  }

  .initial-load {
    text-align: center;
    margin: 48px auto;

    .__infinite-loader {
      margin-bottom: 20px;
    }
  }
}
