@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__piano-roll {
  position: relative;

  .piano {
    position: sticky;
    z-index: 11;
    left: 0;
    width: 200px;

    .overflow-margin-left {
      background-color: $green;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 32px;
      z-index: 10;
    }

    .overflow-margin-right {
      position: absolute;
      z-index: 20;
      overflow: hidden;
      display: flex;
      background-color: $green;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .drums-selector {
    position: sticky;
    z-index: 11;
    left: 0;
    width: 240px;

    .overflow-margin-left {
      background-color: $green;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 32px;
      z-index: 10;
    }

    .drum-row {
      position: absolute;
      height: 22px;
      left: 12px;
      width: 192px;

      button.add {
        all: unset;
        background: $orange;
        color: white;
        font-weight: bold;
        font-size: 14px;
        border-radius: 32px;
        // display: block;
        padding: 0 8px;
        margin-left: 20px;
        // margin: 0 auto;
      }
    }
  }

  .note {
    position: absolute;
    border: 1px solid black;
    border-radius: 16px;
    cursor: pointer;
    pointer-events: none;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    background-color: $green;
    margin-left: 16px;
    user-select: none;

    .scale-marker {
      color: black;
      margin-right: 8px;
    }

    &.full {
      background-color: white;
      width: 152px;
      height: 40px;
      z-index: 10;

      &.highlighted {
        background-color: $light_yellow;
        pointer-events: initial;
      }

      &:hover, &.hover {
        background-color: $lime;
      }
    }

    &.half {
      background-color: black;
      width: 96px;
      height: 26px;
      z-index: 11;

      &.highlighted {
        background-color: $yellow;
        pointer-events: initial;
      }

      &:hover, &.hover {
        background-color: $lime;
      }

      &.A, &.D {
        transform: translateY(-70%);
      }

      &.G {
        transform: translateY(-50%);
      }

      &.C, &.F {
        transform: translateY(-30%); 
      }
    }
  }

  .roll {
    margin-left: 164px;
    padding-top: 16px;
    cursor: pointer;
    overflow: hidden; // to prevent cropped notes outside of the selected duration showing on the editor

    &.drum-track {
      margin-left: 208px;
    }

    .roll-track {
      width: 100%;
      height: 22.8px;
      border-bottom: 1px solid $gray;
      display: flex;
      position: relative;

      &:first-child {
        border-top: 1px solid $gray;
      }

      &.half {
        background-color: $dark_green;
      }

      &.full {
        background-color: $medium_light_green;
      }

      &.disabled {
        background-color: $green;
        border-right: 2px solid $gray;
      }

      .segment {
        width: 30px;

        &:first-of-type {
          border-left: 1px solid $gray;
        }

        &.dashed {
          border-right: 1px dashed $gray;
        }

        &.solid {
          border-right: 1px solid $gray;
        }

        &.double {
          border-right: 2px solid $gray;
        }

        &.division {
          &-4 {
            width: 30px;
          }

          &-5 {
            width: 24px;
          }

          &-6 {
            width: 20px;
          }
        }

        &:hover {
          background-color: rgba($light_yellow, 0.2);
        }

      }

      .single-note {
        position: absolute;
        background-color: $lime;
        border: 1px solid black;  
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        z-index: 1;

      }
      
      .chord-area {
        position: absolute;
        background-color: $light_red;
        border: 1px solid black;  
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        z-index: 1;
        height: calc(22.8px * 12 + 1px);
      }

      .selected {
        background-color: $yellow;
      }

      .playing {
        background-color: $red;
      }
      
      .handle {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 8px;
        
        &:hover {
          background-color: $light_olive;
          cursor: col-resize;
        }
      }
      
      .left-handle {
        left: 0;
      }
      
      .right-handle {
        right: 0;
      }
      
      .center-handle {
        left: 8px;
        right: 8px;
        width: unset;
        
        &:hover {
          background-color: unset;
          cursor: grab;
        }
      }

      .note-velocity {
        position: absolute;
        height: 3px;
        background-color: $purple;
        top: 50%;
        transform: translateY(-50%);
        left: 4px;
        border-radius: 4px;
      }
    }
  }
}
