@import 'node_modules/include-media/dist/_include-media.scss';
@import '../../palette';

.__test-modal {
  .loading{
    text-align: center;
  }
  
  .filter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-control {
      font-size: 0.9rem;
      border-radius: 24px;
      width: 100px;
    }
  }

  .event-results {
    width: 100%;
    margin-top: 20px;
    border: 1px solid $orange;
    text-align: center;

    .event-name {
      text-align: left;
    }

    th {
      background-color: $orange;
      color: $white;
    }

    th, td {
      padding: 12px;
    }

    .positive {
      color: green;
    }
    .negative{
      color: $red;
    }
  }
}
