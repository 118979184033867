@import '../../palette';
@import 'node_modules/include-media/dist/_include-media.scss';

.__pitch-deck {
  height: 100vh;
  width: 100%;
  background-color: $dark_gray;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: $lato;

  // Since the deck container is scaled programmatically on resize, make sure
  // its original width and height are not shown:
  overflow: hidden;

  .__pitch-deck-container {
    width: 1080px;
    height: 800px;
    // background-color: rgba(red, 0.2);
  }

  .navigation {
    display: flex;
    justify-content: space-between;

    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 4px;
    user-select: none;

    .link {
      .arrow {
        font-size: 2rem;
        vertical-align: middle;
      }

      &:hover {
        color: $yellow;
      }
    }
  }

  .slide-container {
    background-color: white;
    width: 100%;
    height: 680px;
    box-shadow: 2px 2px 8px 2px rgba(black, 0.3);
    position: relative;
    overflow: hidden;

    .track-created {
      position: absolute;
      bottom: 1.5rem;
      left: 1.5rem;
      border-radius: 2rem;
      background-color: $dark_red;
      box-shadow: 1px 1px 3px 2px rgba(black, 0.2);
      padding: 0.5rem 2rem;
      font-weight: bold;
      font-size: 1.2rem;
      z-index: 100;

      display: none;

      > span[role=button] {
        color: $yellow;
        
        &:hover{
          text-decoration: underline;
        }
      }

      &.show-enter {
        display: block;
        opacity: 0;
        transform: translateY(1rem) scale(0.8);
        transition: 0.5s;

        &-active {
          opacity: 1;
          transform: none;
        }
      }

      &.show-exit {
        display: block;
        transition: 0.3s;
        opacity: 1;
        transform: none;
        
        &-active {
          opacity: 0;
          transform: translateY(0.5rem);
        }
      }
    }
  }

  .bottom-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .dropdown-options {
      display: flex;
      align-items: center;
      gap: 8px;

      .jump-toggle {
        display: flex;
        gap: 4px;
        width: 20px;
        flex-wrap: wrap;
        margin: 6px;
        

        .square {
          border: 1px solid $white;
          width: 8px;
          height: 8px;
          border-radius: 2px;
        }

        &:hover {
          .square {
            background-color: rgba($white, 0.3);
          }
        }
      }

      .dropdown-menu {
        column-count: 2;
        column-rule: 1px solid $lighter_gray;
        padding: 12px;
        border-radius: 8px;
        box-shadow: 1px 1px 4px 1px rgba(black, 0.3);

        .dropdown-item {
          border-radius: 4px;
          user-select: none;

          &.active, &:active {
            background-color: $green;
          }
        }
      }
    }
  }

  .slide-player {
    background-color: $green;
    padding: 5rem 3rem;
    height: 100%;

    h2 {
      font-weight: bold;
      margin-bottom: 3rem;
    }

    .content {
      position: relative;
    }

    .giant-player {
      background-color: $dark_green;
      border-radius: 8rem;
      padding: 3rem;
      position: relative;
      z-index: 3;
      display: flex;
      align-items: center;

      .toggle-playback {
        display: flex;
        align-items: center;
        padding: 3.5rem;
        background: radial-gradient($yellow 60%, $dark_orange 60%, $dark_orange 64%, $orange 64%);
        border-radius: 100%;
        transition: 0.3s;

        &:hover {
          transform: scale(1.05);
        }

        > svg {
          width: 4rem;
          height: 4rem;
          fill: white;
          filter: drop-shadow(0px 0px 3px rgba($orange, 0.5));
        }
      }

      .rc-slider {
        height: 2rem;
        margin-left: 2.5rem;
    
        &-rail {
          height: 2rem;
          border-radius: 1rem;
        }
    
        &-track {
          background-color: $lime;
          height: 2rem;
          border-radius: 1rem;
        }
    
        &-handle {
          border: 4px solid $purple;
          margin-top: -14px;
          height: 3.6rem;
          width: 3.6rem;
          border-radius: 50%;
        }
      }
    }

    .tag-container {
      position: absolute;
      top: 72%;
      right: 10%;
      z-index: 2;
      transform: rotate(-12deg);
    }

    .create-tag {
      position: relative;
      border-radius: 6rem;
      padding: 0.4rem 1rem 1rem;
      background-color: $yellow;
      display: inline-block;

      .hole {
        background-color: $green;
        width: 1.2rem;
        height: 1.2rem;
        border-radius: 100%;
        margin: 0 auto;
        margin-bottom: 0.4rem;
      }

      .string {
        position: absolute;
        background-color: $orange;
        border-radius: 1rem;
        height: 6rem;
        width: 0.5rem;
        bottom: 85%;
        left: 50%;
        transform: translateX(-50%) rotate(-5deg);
        transform-origin: bottom;
      }
    }

    button.create-track {
      font-family: $fredoka;
      text-transform: uppercase;
      background-color: $red;
      border: none;
      border-radius: 60px;
      display: flex;
      align-items: center;
      padding: 20px;
      font-size: 24px;
      color: white;
      cursor: pointer;
      transition: 0.5s all;
      max-width: 100%;
      justify-content: center;

      &:hover {
        background-color: $light_red;
        transition: 0.5s all;
        transform: scale(1.02);
      }

      .icon {
        margin-inline-end: 8px;
        display: flex;
        
        > svg {
          width: 24px;
          height: 24px;
          fill: white;
        }
      }
    }
  }

  .player {
    font-size: 1.2rem;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .controls {
      display: flex;
      gap: 0.8rem;
      align-items: center;

      .toggle-playback {
        display: flex;
        align-items: center;
        transition: 0.3s;
        background-color: $light_orange;
        border-radius: 100%;
        padding: 10px;
        
        > svg {
          width: 20px;
          height: 20px;
          fill: $white;
        }

        &:hover {
          transform: scale(1.05);
        }
      }

      .marker {
        font-size: 12px;
        font-variant-numeric: tabular-nums; // prevents numbers from resizing (good for timers)
        user-select: none;
      }

      .rc-slider {
        width: 400px;
        margin-top: 8px;
        margin-bottom: 16px;
        margin-right: 20px;
        
        &:hover {
          cursor: pointer;
        }
      
        .rc-slider {
          
          &-handle {
            visibility: hidden;
          }
    
          &-rail {
            height: 12px;
          }
    
          &-track {
            background-color: $lime;
            height: 12px;
            border-radius: 12px;
          }
        }
      }

      button.create-track {
        font-family: $fredoka;
        text-transform: uppercase;
        background-color: $red;
        border: none;
        border-radius: 60px;
        display: flex;
        align-items: center;
        padding: 6px 16px;
        font-size: 18px;
        color: white;
        cursor: pointer;
        transition: 0.5s all;
        max-width: 100%;
        justify-content: center;
        width: 20rem;
  
        &:hover {
          background-color: $light_red;
          transition: 0.5s all;
          transform: scale(1.02);
        }
  
        &.button-disabled {
          pointer-events: none;
          opacity: 0.2;
        }
  
        .icon {
          margin-inline-end: 8px;
          display: flex;
          
          > svg {
            width: 24px;
            height: 24px;
            fill: white;
          }
        }
      }  
    }

    .creating {
      border-radius: 1rem;
      font-weight: bold;
      font-size: 0.9rem;
      padding: 0.3rem 1rem;
      text-transform: uppercase;
      animation: anim-pitch-deck-creating 1.5s infinite alternate ease-in-out 1s, anim-pitch-appear 0.5s;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $dark_orange;
      margin-inline-start: 2.8rem;
      margin-inline-end: 2rem;
      width: 16rem;
      
      > svg {
        margin-inline-end: 0.5rem;
        fill: $light_yellow;
      }

      > span {
        animation: anim-pitch-appear-inner 0.5s;
      }

    }
  }

  .slide {

    &.white-bg {
      background-color: white;
      color: black;

      .strofe-logo-corner {
        .__strofe_logo_white {
          fill: $orange;
        }
      }
    }

    h1 {
      font-size: 2.2rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
      font-weight: bold;
    }

    p {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    .strofe-logo-corner {
      position: absolute;
      top: 0.5rem;
      right: 1rem;
      width: 120px;
    }

    .green-corner {
      position: absolute;
      bottom: 2rem;
      right: 2rem;
      transform: scale(1.5);
      transform-origin: bottom right;
    }

    .bullet-title {
      display: flex;

      .problem {
        transform-origin: top left;
        transform: scale(1.5);
      }
    }
  }

  .slide-cover {
    display: flex;
    flex-direction: column;
    background-color: $green;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .slide-problem {
    padding: 5rem 3rem;

    .content {
      margin-top: 4rem;
      margin-left: 8rem;
    }

    h1 {
      margin-top: 1.6rem;
      margin-left: 2.5rem;
    }
  }

  .slide-solution {

    padding: 5rem 3rem;

    .bullet-title {
      padding-top: 0.5rem;

      h1 {
        margin-top: 1rem;
      }
    }

    .solution-bullet {
      margin-right: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.1rem;

      .purple-circle {
        display: inline-block;
        width: 1.3rem;
        height: 2.5rem;
        border-top-left-radius: 1.3rem;
        border-bottom-left-radius: 1.3rem;
        background-color: $purple;
        margin-right: 0.4rem;
        margin-top: 1.5rem;
      }

      .lime-circle {
        display: inline-block;
        width: 2.4rem;
        height: 4.8rem;
        border-top-left-radius: 2.4rem;
        border-bottom-left-radius: 2.4rem;
        background-color: $lime;
      }
    }

    .content {
      display: flex;
      margin: 1.5rem 1rem 0;
      justify-content: space-between;

      .circle {
        width: 19rem;
        height: 19rem;
        border-radius: 50%;
        padding: 3.5rem 1.5rem;

        // &:not(:last-of-type) {
        //   margin-right: 2rem;
        // }

        &.orange {
          background-color: $yellow;
        }

        &.pink {
          background-color: $papaya;
        }

        h3 {
          font-size: 1.3rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 2rem;
        }

        p {
          font-size: 1.3rem;
          text-align: center;
        }
      }
    }
  }

  .slide-success {

    padding: 5rem 3rem;

    .bullet-title {
      padding-top: 0.5rem;

      h1 {
        margin-top: 1rem;
      }
    }

    .success-bullet {
      margin-right: 1rem;
      position: relative;
      width: 4.8rem;


      .purple-circle {
        position: absolute;
        width: 4.5rem;
        height: 4.5rem;
        border-radius: 100%;
        background-color: $purple;
        right: 0.6rem;
      }

      .lime-circle {
        position: absolute;
        width: 2.1rem;
        height: 2.1rem;
        border-radius: 100%;
        background-color: $lime;
        right: 0rem;
      }

      .orange-circle {
        position: absolute;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 100%;
        background-color: $orange;
        right: 3.5rem;
        top: 2.5rem;
      }
    }

    .content {
      display: flex;
      margin: 1.5rem 2rem 0;
      justify-content: space-between;

      .section {
        width: 18rem;
        display: flex;
        align-items: center;
        flex-direction: column;

        .logo {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 15rem;
          height: 15rem;
          border-radius: 100%;

          img {
            width: 80%;
            margin-bottom: 2rem;
          }
        }

        &.roblox{
          .logo {
            background-color: $orange;
            img {
              margin-bottom: 0;
            }
          }

          .details {
            .valuation {
              background-color: $lime;
            }
            .art{
              background-color: $yellow;
            }
          }
        }

        &.adobe{
          .logo {
            background-color: $lime;
            img {
              width: 40%;
            }
          }

          .details {
            .valuation {
              background-color: $purple;
              color: $white;
            }
            .art{
              background-color: $orange;
              svg {
                margin: -0.6rem -1.2rem 0 0;
              }
            }
          }
        }

        &.autodesk{
          margin-top: 4rem;
          .logo {
            background-color: $yellow;
          }

          .details {
            .valuation {
              background-color: $orange;
            }
            .art{
              background-color: $lime;
            }
          }
        }

        .details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-top: -4rem;

          .valuation {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: blue;
            padding: 1rem;
            border-radius: 15px;
            font-weight: bold;
            font-size: 2rem;
          }

          .art {
            width: 8rem;
            height: 8rem;
            border-radius: 100%;
            background-color: green;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }

  .slide-team {
    padding: 5rem 3rem;

    .bullet-title {
      padding-top: 0.5rem;

      h1 {
        margin-left: 1rem;
      }
    }

    .solution-bullet {
      position: relative;
      height: 4.8rem;
      width: 4.8rem;

      .lime-circle {
        position: absolute;
        width: 3.2rem;
        height: 3.2rem;
        top: 0.5rem;
        border-radius: 100%;
        background-color: $lime;
      }

      .red-circle {
        position: absolute;
        width: 3.6rem;
        height: 3.6rem;
        right: 0rem;
        top: 0rem;
        border-radius: 100%;
        background-color: $red;
      }

      .orange-circle {
        position: absolute;
        width: 1.6rem;
        height: 1.6rem;
        right: 2.6rem;
        top: 1.3rem;
        border-radius: 100%;
        background-color: $orange;
      }

      .papaya-circle {
        position: absolute;
        width: 1.1rem;
        height: 1.1rem;
        right: 2.6rem;
        top: 0.8rem;
        border-radius: 100%;
        background-color: $papaya;
      }

      .purple-circle {
        position: absolute;
        width: 1.3rem;
        height: 1.3rem;
        right: 2.1rem;
        top: 2.1rem;
        border-radius: 100%;
        background-color: $purple;
      }
    }

    .content {
      .member {
        display: flex;
        align-items: center;
        margin: 0 0 20px 40px;
        .avatar {
          z-index: 2;
          background-color: $lime;
          padding: 15px;
          border-radius: 100%;

          img {
            border-radius: 100%;
          }
        }
        .bio {
          z-index: 1;
          background-color: $lime;
          font-size: 1.2rem;
          height: 10rem;
          padding: 0 0 0 50px;
          margin: 0 0 0 -40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 520px;

          .name {
            font-weight: bold;
          }
        }
        .circle{
          height: 10rem;
          width: 5rem;
          border-top-right-radius: 5rem;
          border-bottom-right-radius: 5rem;
          background-color: $lime;
          margin-left: -1px;
        }

        &:last-of-type {
          flex-direction: row-reverse;
          margin: -20px 40px 0 0;
          .avatar {
            background-color: $orange;
          }

          .bio {
            margin: 0 -40px 0 0;
            background-color: $orange;
            padding: 0 50px 0 0;
          }
          .circle {
            border-top-right-radius: 0rem;
            border-bottom-right-radius: 0rem;
            border-top-left-radius: 5rem;
            border-bottom-left-radius: 5rem;
            background-color: $orange;
            margin-right: -1px;
          }
        }
      }
    }
  }

  .slide-traction {
    padding: 5rem 3rem;
    background-color: $green;
    height: 100%;

    .bullet-title {
      padding-top: 0.5rem;

      h1 {
        color: $white;
        margin-top: 1rem;
      }
    }

    .traction-bullet {
      margin-right: 1rem;
      position: relative;
      height: 4.8rem;
      width: 4.8rem;

      .orange-circle {
        position: absolute;
        width: 2.5rem;
        height: 5rem;
        border-top-left-radius: 2.5rem;
        border-bottom-left-radius: 2.5rem;
        background-color: $orange;
      }

      .lime-circle {
        position: absolute;
        width: 3.7rem;
        height: 3.7rem;
        border-radius: 100%;
        background-color: $lime;
        right: 0;
      }

      .yellow-circle {
        position: absolute;
        width: 1.3rem;
        height: 2.5rem;
        border-top-right-radius: 1.3rem;
        border-bottom-right-radius: 1.3rem;
        background-color: $yellow;
        right: 0.4rem;
        top: 2.5rem;
      }
    }


    .content {
      display: flex;
      margin: 1.5rem 1rem 0;
      justify-content: space-between;

      .circle {
        width: 19rem;
        height: 19rem;
        border-radius: 50%;
        padding: 3.5rem 1.5rem;
        color: black;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &.orange {
          background-color: $orange;
        }

        &.lime {
          background-color: $lime;
        }

        &.papaya {
          background-color: $papaya;
        }

        h3 {
          font-size: 2.5rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 1rem;
        }

        p {
          font-size: 2.5rem;
          text-align: center;
        }
      }
    }
  }

  .slide-roblox {
    padding: 5rem 3rem;
    background-color: $green;
    height: 100%;

    .bullet-title {
      padding-top: 0.5rem;

      h1 {
        color: $white;
        margin-top: 1rem;
      }
    }

    .traction-bullet {
      margin-right: 1rem;
      position: relative;
      height: 4.8rem;
      width: 4.8rem;

      .orange-circle {
        position: absolute;
        width: 2.5rem;
        height: 5rem;
        border-top-left-radius: 2.5rem;
        border-bottom-left-radius: 2.5rem;
        background-color: $orange;
      }

      .lime-circle {
        position: absolute;
        width: 3.7rem;
        height: 3.7rem;
        border-radius: 100%;
        background-color: $lime;
        right: 0;
      }

      .yellow-circle {
        position: absolute;
        width: 1.3rem;
        height: 2.5rem;
        border-top-right-radius: 1.3rem;
        border-bottom-right-radius: 1.3rem;
        background-color: $yellow;
        right: 0.4rem;
        top: 2.5rem;
      }
    }


    .content {
      display: flex;
      margin: 1.5rem 1rem 0;
      justify-content: center;

      .circle {
        width: 24rem;
        height: 24rem;
        border-radius: 50%;
        padding: 3.5rem 1.5rem;
        color: black;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &.orange {
          background-color: $orange;
        }

        &.lime {
          background-color: $lime;
        }

        &.papaya {
          background-color: $papaya;
        }

        h3 {
          font-size: 2.5rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 1rem;
        }

        p {
          font-size: 2.5rem;
          text-align: center;
        }
      }
    }

    .footer {
      margin-top: 2rem;

      a {
        color: $yellow;
      }
    }
  }

  .slide-testimonials {
    padding: 5rem 3rem;

    .bullet-title {
      padding-top: 0.5rem;

      h1 {
        margin-top: 1rem;
        margin-left: 1rem;
      }
    }

    .traction-bullet {
      position: relative;
      width: 4.8rem;
      height: 4.8rem;

      .orange-circle {
        position: absolute;
        width: 2rem;
        height: 4rem;
        border-top-left-radius: 2rem;
        border-bottom-left-radius: 2rem;
        background-color: $orange;
        right: 1rem;
        transform: rotate(30deg);
      }

      .yellow-circle {
        position: absolute;
        width: 1.3rem;
        height: 2.5rem;
        border-top-left-radius: 1.3rem;
        border-bottom-left-radius: 1.3rem;
        background-color: $yellow;
        right: 2.8rem;
        top: 0.9rem;
        transform: rotate(30deg);
      }

      .lime-circle {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
        background-color: $lime;
        top: 1.9rem;
        right: 0.5rem;
      }
    }

    .content {
      display: flex;
      margin: 0.5rem 0;
      flex-wrap: wrap;
      flex-direction: row;
      height: 400px;
      width: 85%;

      .NPS {
        position: absolute;
        margin-top: auto;
        background-color: $purple;
        color: white;
        border-radius: 1.5rem;
        font-size: 1.1rem;
        font-weight: bold;
        height: 8rem;
        width: 9rem;
        text-align: center;
        top: 12.8rem;
        right: 5.5rem;
        padding-top: 1rem;
      
        .count {
          font-size: 3.8em;
          line-height: 4.5rem;
          font-weight: bold;
        }
      }

      .testimonial {
        height: 50%;
        width: 50%;
        display: flex;
        flex-direction: column;
        
        &.first {
          align-items: center;
          .quote {
            background-color: $yellow;
          }
          .author {
            margin: 0 100px auto auto;
            .triangle {
              border-color: $yellow transparent transparent transparent;
            }
          }
        }
        &.second {
          .quote {
            background-color: $orange;
          }
          .author {
            margin: 0 170px auto auto;
            .triangle {
              border-color: $orange transparent transparent transparent;
            }
          }
        }
        &.third {
          align-items: end;
          .quote {
            background-color: $papaya;
          }
          .author {
            margin: 0 30px auto auto;
            .triangle {
              border-color: $papaya transparent transparent transparent;
            }
          }
        }
        &.fourth {
          align-items: center;
          .quote {
            background-color: $lime;
          }
          .author {
            margin: 0 100px auto auto;
            .triangle {
              border-color: $lime transparent transparent transparent;
            }
          }
        }

        .quote {
          border-radius: 20px;
          padding: 16px;
          width: 82%;
          height: 8rem;
        }

        .author {
          text-align: right;
          display: flex;

          .triangle {
            margin-top: -1px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 30px 30px 0 0;
          }

          .name {
            margin: 10px 10px 0 0;
            font-weight: bold;
          }
        }
      }
    }
  }

  .slide-product-sketch {
    padding: 5rem 3rem;

    .bullet-title {
      padding-top: 0.5rem;

      h1 {
        margin-top: 1rem;
        margin-left: 0.5rem;
      }
    }

    .bullet {
      position: relative;
      width: 4.8rem;
      height: 4.8rem;

      .semi-circle {
        position: absolute;
        top: 0.4rem;
        right: 0;
        background-color: $lime;
        width: 50%;
        height: 100%;
        border-top-left-radius: 4rem;
        border-bottom-left-radius: 4rem;
        margin-left: -0.4rem;
      }

      .circle-top {
        width: 75%;
        height: 75%;

        position: absolute;
        top: 0;
        left: 0;

        .circle {
          border-radius: 100%;
          background-color: $orange;
          width: 100%;
          height: 100%;
        }
      }
    }

    .content {
      display: flex;
      margin: 1.5rem 2rem 0;
      justify-content: space-between;

      div {
        text-align: center;
        font-size: 1.5rem;
      }

      .circle {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items:center;
        margin-bottom: 10px;

        &.create {
          margin-top: 70px;
          background-color: $olive;
          width: 13rem;
          height: 13rem;
        }

        &.edit {
          background-color: $green;
          width: 12rem;
          height: 12rem;
        }

        &.purchase {
          margin-top: 50px;
          background-color: $olive;
          width: 15rem;
          height: 15rem;
        }

        h3 {
          font-size: 1.1rem;
          font-weight: bold;
          text-align: center;
          margin-bottom: 2rem;
        }

        p {
          font-size: 1.1rem;
          text-align: center;
        }
      }
    }
  }

  .slide-product-editor {
    height: 100%;
    padding: 7rem 1rem;
    background-color: $green;

    img.screenshots {
      width: 100%;
    }
  }

  .slide-product-create {
    height: 100%;
    padding: 4rem 3rem;
    background-color: $green;

    img.screenshots {
      width: 100%;
    }
  }

  .slide-product-abtests {
    height: 100%;
    padding: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    img.screenshots {
      height: 100%;
    }
  }

  .slide-business-model {
    padding: 5rem 3rem 0;

    .bullet-title {
      padding-top: 0.5rem;

      h1 {
        margin-top: 1rem;
        margin-left: 0.5rem;
      }
    }

    .bullet {
      position: relative;
      width: 4.8rem;
      height: 4.8rem;

      .semi-circle {
        position: absolute;
        border-top-left-radius: 4rem;
        border-bottom-left-radius: 4rem;
        margin-left: -0.4rem;
      }

      .green {
        width: 40%;
        height: 80%;
        right: 0rem;
        background-color: $lime;
      }

      .orange {
        width: 50%;
        height: 100%;
        right: 0.8rem;
        background-color: $orange;
      }

      .purple {
        right: 0rem;
        top: 1.7rem;
        background-color: $purple;
        width: 30%;
        height: 60%;
      }
    }

    .content {
      display: flex;
      margin: 1.5rem;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(to bottom, transparent 10%, $dark_green 10%, $dark_green 90%, transparent 90%);
      border-radius: 60px;

      .deal {
        height: 375px;
        width: 270px;
        border-radius: 50px;
        padding: 2rem;
        text-align: center;

        &.free {
          background-color: $papaya;
        }

        &.standard {
          background-color: $orange;
        }

        &.premium {
          background-color: $lime;
        }

        .title {
          font-size: 1.2rem;
          font-weight: bold;
        }

        .price {
          background-color: $purple;
          color: $white;
          font-size: 1.3rem;
          font-weight: bold;
          border-radius: 30px;
          padding: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 1rem auto;
        }

        .description {
          text-align: start;
          margin-bottom: 1rem;
        }

        .perks {
          text-align: start;
          font-weight: bold;

          ul {
            padding-left: 1rem;
          }
        }
      }
    }
  }

  .slide-go-to-market {
    padding: 5rem 3rem;

    .bullet-title {
      padding-top: 0.5rem;

      h1 {
        margin-top: 1rem;
        margin-left: 0.5rem;
      }
    }

    .bullet {
      position: relative;
      width: 4.8rem;
      height: 4.8rem;

      .circle {
        position: absolute;
        border-radius: 100%;
      }

      .orange {
        width: 40%;
        height: 40%;
        right: 2.6rem;
        top: 1.5rem;
        background-color: $orange;
      }

      .red {
        width: 50%;
        height: 50%;
        right: 0rem;
        top: 0.3rem;
        background-color: $red;
      }

      .purple {
        right: 0.3rem;
        top: 3rem;
        background-color: $purple;
        width: 30%;
        height: 30%;
      }
    }

    .content {
      display: flex;
      margin: 4.5rem 1.5rem;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(to bottom, transparent 20%, $purple 20%, $purple 80%, transparent 80%);
      border-radius: 60px;

      .deal {
        height: 200px;
        width: 270px;
        border-radius: 50px;
        padding: 2rem;
        text-align: center;

        &.free {
          background-color: $papaya;
        }

        &.standard {
          background-color: $orange;
        }

        &.premium {
          background-color: $lime;
        }

        .title {
          font-size: 1.2rem;
          font-weight: bold;
        }

        .description {
          text-align: start;
          margin-top: 1rem;
        }
      }
    }
  }

  .slide-product {
    padding: 5rem 3rem;

    .bullet-title {
      margin-top: 1rem;

      h1 {
        margin-top: 0.5rem;
        margin-left: 0.5rem;
        margin-bottom: 0;
      }
      
      .bullet {
        position: relative;
        width: 5.5rem;
  
        .circle {
          position: absolute;
          border-radius: 50%;
  
          &.big-lime {
            background-color: $lime;
            width: 4rem;
            height: 4rem;
          }
  
          &.small-orange {
            background-color: $orange;
            width: 2.2rem;
            height: 2.2rem;
            right: 0;
            top: 0.1rem;
          }
  
          &.small-purple {
            background-color: $purple;
            width: 1.2rem;
            height: 1.2rem;
            right: 0.4rem;
            bottom: 0.4rem;
          }
        }
      }  
    }

    .content {
      text-align: center;

      img.screenshots {
        width: 80%;
        height: auto;
      }
    }
  }

  .slide-business-model-test {
    padding: 5rem 3rem;

    .bullet-title {
      h1 {
        margin-top: 1rem;
        margin-left: 0.5rem;
        width: 53rem;
      }
    }

    .bullet {
      position: relative;
      width: 4.8rem;
      height: 4.8rem;
      margin-top: 1.2rem;

      .semi-circle {
        background-color: $orange;
        width: 50%;
        height: 100%;
        border-top-left-radius: 4rem;
        border-bottom-left-radius: 4rem;
        margin-left: -0.4rem;
      }

      .circle-top {
        width: 50%;
        height: 50%;

        position: absolute;
        top: 0;
        right: 0;
        padding: 0.4rem;

        .circle {
          border-radius: 100%;
          background-color: $lime;
          width: 100%;
          height: 100%;
        }
      }

      .circle-bottom {
        width: 50%;
        height: 50%;

        position: absolute;
        top: 50%;
        right: 0;
        padding: 0.4rem;

        .circle {
          border-radius: 100%;
          background-color: $papaya;
          width: 100%;
          height: 100%;
        }
      }
    }

    .content {
      margin-top: 0.5rem;
    }

    .pill {
      display: flex;
      align-items: center;

      padding: 0rem 1.8rem;
      border-radius: 4rem;
      width: 25rem;
      height: 5rem;
      margin-bottom: 1.5rem;

      &:nth-child(even) {
        background-color: $yellow;
      }

      &:nth-child(odd) {
        background-color: $lime;
      }

      .number {
        background-color: $purple;
        font-size: 2rem;
        color: white;
        font-weight: bold;
        border-radius: 50%;
        display: flex;
        width: 3rem;
        height: 3rem;
        margin-right: 0.8rem;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
      }

      .text {
        font-size: 1.5rem;
      }
    }
  }

  .slide-try-me {

    padding: 5rem 3rem;
    background-color: $green;
    height: 100%;
    text-align: center;

    .logo {
      width: 28rem;
      display: block;
      margin: 0 auto;
    }

    .create-song {
      margin-left: 12rem;
      transition: 0.5s;

      &:hover {
        transform: scale(1.05);
        filter: brightness(1.05) drop-shadow(2px 2px 4px rgba(white, 0.2));
      }
    }

    .__loading-blocks {

      padding: 4rem 1rem;

      p {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 3rem;
      }
    }
  }

  .slide-try-me-advanced {
    background-color: $dark_green;
    height: 100%;
    padding: 3rem;

    p {
      margin-bottom: 0.3rem;
    }

    .picker {
      overflow: auto;
      max-height: 100%;
    }

    .moods {

      display: flex;
      gap: 12px;
      margin-bottom: 2rem;

      .mood-icon {
        padding: 12px;
        width: 120px;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: center;
        border-radius: 16px;

        > svg {
          margin-bottom: 4px;
        }

        &:hover, &.active {
          background-color: $green;
        }
      }
    }

    .genres {

      margin-bottom: 2rem;

      .style-button {
        text-align: center;
        font-size: 20px;
        flex: 1;
        margin: 4px;
        border: 1px solid transparent;
        color: $white;
        width: 132px;
        
        &.mood {
          font-weight: bold;
  
          &:hover {
            background-color: rgba(white, 0.1);
          }
    
          &.active {
            background-color: rgba(white, 0.1);
            border-color: transparent;
          }
        }
  
        &.genre {
          font-size: 16px;
          flex-basis: 25%;
          max-width: 32%;
          border: none;
  
          &.active {
            background-color: transparent;
            border: none;
            box-shadow: none;
  
            .hovered {
              opacity: 1;
            }
    
            .outline {
              opacity: 0;
            }
          }  
  
          &:active {
            box-shadow: none;
          }
  
          &:hover {
            background-color: rgba(white, 0.1);
  
            .hovered {
              opacity: 1;
              transition: 0.3s;
            }
  
            .outline {
              opacity: 0;
              transition: 0.15s;
            }
          }
          
          .icon-container {
            position: relative;
          }
  
          .hovered {
            opacity: 0;
          }
  
          .outline {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
  
          .genre-text {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 20px;
          }
  
          .text-bossa, .text-orc_pop, .text-retro_futuristic {
            font-size: 14px;
          }
        }
        
        @include media("<=720px") {
          flex-basis: 25%;
          max-width: 32%;
        }
  
        .icon {
          font-size: 48px;
          padding: 8px;
        }
      }
    }

    .create-song-button {
      font-family: $fredoka;
      text-transform: uppercase;
      background-color: $red;
      border: none;
      border-radius: 60px;
      display: flex;
      align-items: center;
      padding: 8px 24px;
      margin: 20px auto;
      font-size: 36px;
      color: white;
      cursor: pointer;
      transition: 0.5s all;
      max-width: 100%;
      justify-content: center;

      &:hover {
        background-color: $light_red;
        transition: 0.5s all;
        transform: scale(1.02);
      }

      &.button-disabled {
        pointer-events: none;
        opacity: 0.2;
      }

      .icon {
        margin-inline-end: 8px;
        display: flex;
        
        > svg {
          width: 36px;
          height: 36px;
          fill: white;
        }
      }
    }
  }

  .slide-roadmap {
    padding: 5rem 3rem;
    height: 100%;
    background-color: $green;

    .bullet-title {
      margin-top: 1rem;

      .problem {
        transform: rotate(-90deg) scale(1.5);
        transform-origin: unset;
        margin-left: 1rem;
        margin-top: 0.5rem;
      }

      h1 {
        margin-left: 2rem;
      }
    }

    .content {
      padding: 3rem 2rem;
    }

    .roadmap {
      height: 16rem;
      position: relative;

      .line {
        position: absolute;
        background: linear-gradient(to right, $orange 45%, $yellow 50%);
        height: 0.3rem;
        top: 50%;
        width: 100%;
        transform: translateY(-50%);
        border-radius: 1rem;
      }

      .milestone {
        position: absolute;
        background-color: $orange;
        color: black;
        border-radius: 0.8rem;
        font-weight: bold;
        padding: 0.3rem 0.8rem;
        min-width: 10rem;
        line-height: 1.3rem;
        text-align: center;
        white-space: nowrap;

        &.yellow {
          background-color: $yellow;

          .vertical-connector {
            background-color: $yellow;

            .dot {
              background-color: $yellow;
            }
          }
        }

        &:nth-of-type(odd) {
          bottom: 0;

          .vertical-connector {
            bottom: 100%;

            .dot {
              top: 0;
              transform: translate(-50%, -38%);
            }
          }
        }

        &:nth-of-type(even) {
          top: 0;

          .vertical-connector {
            top: 100%;

            .dot {
              bottom: 0;
              transform: translate(-50%, 38%);
            }
          }
        }

        .vertical-connector {
          position: absolute;
          width: 0.5rem;
          left: 50%;
          background-color: $orange;
          height: 5rem;
          transform: translateX(-50%);

          .dot {
            position: absolute;
            background-color: $orange;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 100%;
            left: 50%;
          }
        }
      }
    }
  }

  .slide-market-validation-2 {
    padding: 5rem 3rem;
    height: 100%;
    background-color: $green;

    .bullet-title {
      h1 {
        margin-top: 1rem;
        margin-left: 0.5rem;
      }
    }

    .bullet {
      position: relative;
      width: 4.8rem;
      height: 4.8rem;

      .semi-circle {
        background-color: $orange;
        width: 50%;
        height: 100%;
        border-top-left-radius: 4rem;
        border-bottom-left-radius: 4rem;
        margin-left: -0.4rem;
      }

      .circle-top {
        width: 50%;
        height: 50%;

        position: absolute;
        top: 0;
        right: 0;
        padding: 0.4rem;

        .circle {
          border-radius: 100%;
          background-color: $lime;
          width: 100%;
          height: 100%;
        }
      }

      .circle-bottom {
        width: 50%;
        height: 50%;

        position: absolute;
        top: 50%;
        right: 0;
        padding: 0.4rem;

        .circle {
          border-radius: 100%;
          background-color: $papaya;
          width: 100%;
          height: 100%;
        }
      }
    }

    .content {
      margin-top: 0.5rem;
    }

    .pill {

      display: flex;
      align-items: center;

      padding: 0rem 1.6rem;
      border-radius: 4rem;
      width: 30rem;
      height: 6.6rem;
      margin-bottom: 1.5rem;
      color: black;

      &.wide {
        width: 40rem;
      }

      &:nth-child(even) {
        background-color: $lime;
      }
      
      &:nth-child(odd) {
        background-color: $yellow;
      }

      .number {
        background-color: $purple;
        font-size: 2rem;
        color: white;
        font-weight: bold;
        border-radius: 50%;
        display: flex;
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
      }

      .text {
        line-height: 1.35rem;
      }
    }
  }

  .slide-market-validation {
    padding: 5rem 3rem;
    background-color: $green;
    height: 100%;

    .bullet-title {
      h1 {
        margin: 1rem 0 0;
      }
    }

    .bullet {
      margin-right: 1rem;
      position: relative;
      height: 4.8rem;
      width: 4.8rem;

      .red-circle {
        position: absolute;
        width: 2rem;
        height: 2rem;
        border-radius: 100%;
        background-color: $red;
        left: 0.6rem;
        top: 0.4rem;
      }

      .lime-circle {
        position: absolute;
        width: 2.5rem;
        height: 5rem;
        border-top-right-radius: 2.5rem;
        border-bottom-right-radius: 2.5rem;
        background-color: $lime;
        left: 2rem;
      }

      .yellow-circle {
        position: absolute;
        width: 3.6rem;
        height: 3.6rem;
        border-radius: 100%;
        background-color: $yellow;
        top: 1rem;
      }
    }

    .content {
      position: relative;
      height: 21.5rem;
      margin-top: 3rem;

      .bg {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .total-users {
        border-radius: 1.4rem;
        text-transform: uppercase;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $yellow;
        font-size: 1.6rem;
        line-height: 2.2rem;
        color: black;
        width: 15rem;
        padding: 1.2rem;
        font-weight: bold;
        text-align: center;
        top: 50%;
      }

      .bullet {
        display: flex;
        align-items: center;
        background-color: $orange;
        color: black;
        width: 12rem;
        border-radius: 1.4rem;
        text-transform: uppercase;
        padding: 0 2rem;
        height: 5rem;
        font-size: 1.2rem;
        line-height: 1.5rem;
        font-weight: bold;
        text-align: center;
      }

      .head {
        position: absolute;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 100%;
      }

      .line {
        position: absolute;
        width: 8rem;
        height: 0.5rem;
      }

      .video-game-devs {
        position: absolute;
        top: 50%;
        left: 0;

        .bullet {
          background-color: $orange;
        }

        .head {
          background-color: $orange;
          top: 50%;
          left: 100%;
          margin-left: 0.5rem;
          transform: translateY(-50%);
        }

        .line {
          background-color: $orange;
          top: 50%;
          left: 100%;
          margin-left: 1rem;
          transform: translateY(-50%);
        }
      }

      .youtubers {
        position: absolute;
        top: 10%;
        right: 3%;

        .bullet {
          background-color: $lime;
        }

        .head {
          background-color: $lime;
          top: 50%;
          right: 100%;
          margin-right: 0.5rem;
          transform: translateY(-50%);
        }

        .line {
          background-color: $lime;
          top: 50%;
          right: 100%;
          margin-right: 1rem;
          transform: translateY(-50%);
        }
      }

      .streamers {
        position: absolute;
        top: 70%;
        right: 0%;

        .bullet {
          background-color: $red;
        }

        .head {
          background-color: $red;
          top: 50%;
          right: 100%;
          margin-right: 0.5rem;
          transform: translateY(-50%);
        }

        .line {
          background-color: $red;
          top: 50%;
          right: 100%;
          margin-right: 1rem;
          transform: translateY(-50%);
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;

      a {
        color: $yellow;
        font-size: 12px;
      }
    }
  }

  .slide-competitors {
    padding: 5rem 3rem;

    .bullet-title {
      margin-top: 1rem;

      .bullet {
        position: relative;
        width: 2.6rem;

        .circle-big-purple {
          border-top-left-radius: 4rem;
          border-bottom-left-radius: 4rem;
          width: 2rem;
          height: 4rem;
          background-color: $purple;
        }

        .circle-small-lime {
          border-top-left-radius: 2.4rem;
          border-bottom-left-radius: 2.4rem;
          width: 1.2rem;
          height: 2.4rem;
          background-color: $lime;
          position: absolute;
          right: 0;
          top: 0.6rem;
        }
      }

      h1 {
        margin-left: 1rem;
      }
    }

    table.competitors {
      width: 85%;
      margin: 0 auto;
      font-size: 1.2rem;
      border-collapse: separate; // do not use collapse, td's border radius will show incorrectly
      border-spacing: 0;

      th, td {
        border-top: 2px solid white;
        border-bottom: 2px solid white;
      }

      tr {
        border-bottom: 0.3rem solid white;
      }

      th {
        text-align: center;
        padding: 0.3rem 1rem;
        line-height: 1.3rem;

        width: 19%;

        &:first-of-type {
          width: 24%;
        }

        border-top-left-radius: 1.2rem;
        border-top-right-radius: 1.2rem;

        &.strofe {
          background-color: $lime;
        }

        &.amper {
          background-color: $papaya;
        }
      }

      tbody {
        td {
          padding: 0.5rem 1rem;
          font-size: 1.1rem;
          line-height: 1.2rem;
          
          &:not(:first-of-type) {
            text-align: center;
          }

          &.strofe {
            background-color: $lime;
          }

          &.amper {
            background-color: $papaya;
          }
        }
        
        tr {
          
          height: 3.8rem;

          &:nth-of-type(odd) {
            td:not(.strofe) {
              background-color: $orange;

              &:first-of-type {
                border-top-left-radius: 1.2rem;
                border-bottom-left-radius: 1.2rem;
              }

              &:last-of-type {
                border-top-right-radius: 1.2rem;
                border-bottom-right-radius: 1.2rem;
              }
            }
          }

          &:nth-last-of-type(2) {
            td {
              border-bottom: none;
            }
          }

          &:last-of-type {
            height: 2.2rem;
            
            td {
              border-top: none;
              border-bottom-left-radius: 1.2rem;
              border-bottom-right-radius: 1.2rem;
            }
          }
        }
      }
    }
  }

  .slide-competitors-graph {
    padding: 5rem 3rem;

    .bullet-title {
      .bullet {
        position: relative;
        width: 5rem;

        .circle-big-yellow {
          border-top-left-radius: 5rem;
          border-bottom-left-radius: 5rem;
          width: 2.5rem;
          height: 5rem;
          background-color: $yellow;
          left: 0.5rem;
          position: absolute;
        }

        .circle-small-lime {
          border-top-left-radius: 3.4rem;
          border-top-right-radius: 3.4rem;
          width: 3.4rem;
          height: 1.7rem;
          background-color: $lime;
          position: absolute;
          top: 0.8rem;
        }
        
        .circle-small-red {
          position: absolute;
          width: 1.3rem;
          height: 1.3rem;
          left: 3.8rem;
          top: 1.2rem;
          background-color: $red;
          border-radius: 100%;
        }

        .circle-small-purple {
          position: absolute;
          width: 1.8rem;
          height: 1.8rem;
          left: 2.5rem;
          top: 2.8rem;
          background-color: $purple;
          border-radius: 100%;
        }
      }

      h1 {
        margin-left: 1rem;
        margin-top: 1rem;
      }
    }

    .content {
      .chart {
        position: relative;
        margin: 0 auto;
        background-color: $orange;
        width: 50rem;
        height: 24rem;
        border-radius: 3rem;

        .axis-label {
          position: absolute;
          font-size: 3rem;
          font-weight: bold;
          text-align: center;
        }
        
        .ease-of-use {
          bottom: 1rem;
          width: 100%;
        }
        
        .scalability {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          left: 2rem;
          top: 3rem;
        }

        .graph {
          width: 82%;
          height: 67%;
          top: 2.5rem;
          right: 2.5rem;
          background-color: $yellow;
          position: absolute;
          border-top-right-radius: 3rem;
          border-bottom: 0.4rem solid white;
          border-left: 0.4rem solid white;

          img {
            position: absolute;
            
            &.aiva {
              height: 3rem;
              top: 20%;
              left: 5%;
            }

            &.mubert {
              top: 38%;
              left: 32%;
              height: 1.8rem;
            }

            &.soundtrap {
              top: 65%;
              left: 18%;
              height: 1.5rem;
            }

            &.amper {
              top: 61%;
              left: 45%;
              height: 2rem;
            }

            &.boomy {
              top: 53%;
              left: 62%;
              height: 1.6rem;
            }

            &.epidemic-sound {
              top: 75%;
              left: 55%;
              height: 2rem;
            }  
          }

          .strofe-logo-black {
            position: absolute;
            height: 5rem;
            top: 6%;
            right: 4%;

            .__strofe_logo_white {
              fill: black;
            }
          }

          .uppbeat-logo {
            position: absolute;
            height: 2.7rem;
            top: 72%;
            right: 8%;
          }
        }        
      }
    }
  }

  .slide-before-strofe {
    padding: 5rem 3rem;

    .bullet-title {
      display: flex;
      align-items: center;

      .bullet {
        margin-right: 1rem;
  
        > svg {
          width: 5rem;
          height: 5rem;
        }
      }

      h1 {
        margin-bottom: 0;
      }
    }

    .content {
      margin-top: 7rem;
    }

    .persona {
      display: flex;
      background-color: $gray;
      border-radius: 8rem;
      margin: 2rem auto;
      width: 54rem;

      .avatar {
        background-color: $light_gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.8rem solid $gray;
        border-radius: 100%;
        width: 11rem;
        height: 11rem;
        transform: scale(1.2);
        padding: 0.5rem;

        .head {
          background-color: $gray;
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
          margin-bottom: 0.5rem;
        }

        .torso {
          background-color: $gray;
          border-radius: 1rem;
          width: 4.4rem;
          height: 4.4rem;
        }
      }

      .description {
        flex: 1;
        color: white;
        font-size: 1.2rem;
        line-height: 1.6rem;
        font-weight: 600;
        padding: 0.5rem 4rem 0.5rem 2rem;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }

    .user-name {
      font-weight: 700;
      margin-left: 5rem;
      margin-top: 1.5rem;
      font-size: 2rem;
    }
  }

  .slide-after-strofe {
    padding: 5rem 3rem;

    .bullet-title {
      display: flex;
      align-items: center;

      .bullet {
        margin-right: 1rem;
  
        > svg {
          width: 5rem;
          height: 5rem;
        }
      }

      h1 {
        margin-bottom: 0;
      }
    }

    .content {
      margin-top: 11rem;
    }

    .persona {
      position: relative;
      display: flex;
      background-color: $orange;
      border-radius: 8rem;
      margin: 2rem auto;
      width: 54rem;

      .avatar {
        background-color: $purple;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 0.8rem solid $orange;
        border-radius: 100%;
        width: 11rem;
        height: 11rem;
        transform: scale(1.2);
        padding: 0.5rem;

        .head {
          background-color: $lime;
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
          margin-bottom: 0.5rem;
        }

        .torso {
          background-color: $lime;
          border-radius: 1rem;
          width: 4.4rem;
          height: 4.4rem;
        }
      }

      .description {
        flex: 1;
        font-size: 1.2rem;
        line-height: 1.6rem;
        padding: 1rem 4rem 1rem 6rem;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      .testimonial {
        position: absolute;
        width: 22rem;
        top: -11rem;
        right: 4rem;

        .quote {
          background-color: $yellow;
          border-radius: 20px;
          padding: 16px;
          height: 8rem;
        }

        .arrow {
          width: 0;
          margin: 0 16rem;
          border-style: solid;
          border-width: 30px 0 0 30px;
          border-color: $yellow transparent transparent transparent;
        }
      }
    }

    .user-name {
      font-weight: 700;
      margin: 1.5rem 5.5rem;
      text-align: right;
      font-size: 2rem;
    }
  }

  .slide-video-game-growth {
    padding: 5rem 3rem;

    .bullet-title {
      display: flex;
      align-items: center;

      .bullet {
        margin-right: 1rem;
  
        > svg {
          width: 5rem;
          height: 5rem;
        }
      }

      h1 {
        margin-bottom: 0;
      }
    }

    .content {
      margin-top: 2rem;
      position: relative;
      height: 20rem;

      .tag {
        position: absolute;
        transform: translateY(-50%);
        border-radius: 100%;
        width: 100px;
        height: 100px;
        
        .text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          white-space: pre;
          font-size: 0.6rem;
          line-height: 0.8rem;
          font-weight: bold;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      margin-top: 3rem;

      a {
        color: $green;
      }
    }
  }
}

@keyframes anim-pitch-deck-creating {
  from {
    background-color: $dark_orange;
  }  
  to {
    background-color: rgba($dark_orange, 0.6);
  }
}

@keyframes anim-pitch-appear {
  from {
    opacity: 0;
    transform: translateY(0.5rem);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes anim-pitch-appear-inner {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
