@import '../../palette';
@import 'node_modules/include-media/dist/_include-media.scss';

.__landing-page {

  font-family: 'Poiret One', sans-serif;
  overflow-x: hidden;

  .action-button {
    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    background-color: $yellow;
    border: none;
    border-radius: 32px;
    padding: 0px 12px;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: bold;
    font-family: 'Poiret One', sans-serif;
    color: $green;
    letter-spacing: 5px;
    cursor: pointer;
    transition: 0.3s all;
    width: 100%;

    &.bigger {
      padding: 8px 12px;
      font-size: 28px;
    }

    &:hover {
      background-color: $light_yellow;
      transition: 0.3s all;
    }

    &:disabled {
      color: #8f8f8f;
    }
  }

  .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80vh;
    padding: 8px;
    max-width: 80%;
    text-align: center;
    background-color: $green;
    width: 550px;
    margin: 0 auto;

    &-container {
      animation: 0.6s animation-appear;
    }

    .button-container {
      width: 320px;
      max-width: 100%;
      margin: 0 auto;
    }

    .strofe-logo {
      max-width: 100%;
      width: 320px;
      margin: 0 auto;
    }
  
    p {
      font-size: 22px;
      color: $white;
    }

    h2 {
      margin-top: 12px;
      margin-bottom: 40px;
      line-height: 44px;
      font-size: 36px;
      font-family: $lato;
    }
  }

  .copy-text {

    background-color: white;
    // color: $dark_green;
    color: black;
    font-size: 32px;
    line-height: 36px;

    @include media("<=720px") {
      font-size: 24px;
      line-height: 32px;
    }

    .top-divider, .bottom-divider {
      display: block;
    }

    .copy-mobile {
      display: none;
      max-width: 90%;
      margin: 0 auto;

      p {
        margin: 40px 0;
      }
      
      @include media("<=720px") {
        display: block;
      }
    }
    
    .copy-grid {

      @include media("<=720px") {
        display: none;
      }
  
      margin: 0 auto;
      padding: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;

      .curve-1 {
        text-align: right;
        
        > svg {
          width: 400px;
        }
      }

      .curve-2 {
        position: relative;

        .line {
          width: 400px;
          margin-bottom: 40px;
        }

        .svg-bg {
          position: absolute;
          right: -48px;
          width: 360px;
          top: -140px;
        }
      }

      .curve-3 {
        position: relative;
        text-align: right;

        .line {
          width: 400px;
        }

        .svg-bg {
          position: absolute;
          left: -100px;
          width: 320px;
        }
      }

      .figures-2 {
        position: relative;

        .svg-bg {
          position: absolute;
          width: 400px;
          right: -100px;
          z-index: 2;
        }
      }

      .copy-what-is {
        position: relative;
        
        .text {
          margin-top: 90px;
          margin-left: 120px;
          margin-right: 80px;
        }

        .svg-bg {
          position: absolute;
          left: -100px;
          width: 400px;
        }
      }

      .copy-purpose {
        position: relative;

        .text {
          margin-top: 60px;
          margin-right: 160px;
        }
      }

      .copy-simple-tool {
        align-self: end;
        margin-left: 120px;
        margin-bottom: 20px;
      }

      .copy-easy-usage {
        position: relative;

        .copy {
          margin-right: 200px;
          margin-top: 60px;
        }

        .svg-bg {
          position: absolute;
          width: 180px;
          right: 0;
          top: 0;
        }
      }
    }

    .mood-info {
      margin: 0 auto;
      padding-top: 180px;
      width: 900px;
      max-width: 90%;

      .mood-title {
        font-size: 1.4em;
        text-align: center;
      }

      @include media("<=720px") {
        padding-top: 20px;
        padding-bottom: 40px;
      }

      .mood-icons {
        display: flex;
        margin: 40px auto;
        flex-wrap: wrap;
        justify-content: center;
        
        .mood-button {
          flex: 1;
          text-align: center;
          border-radius: 16px;
          padding: 12px;
          transition: 0.3s;
  
          &:hover {
            transform: translateY(-5px);
            background-color: rgba(black, 0.05);
            transition: 0.3s;
          }

          @include media("<=720px") {
            flex-basis: 33%;
            max-width: 33%;
          }

          > svg {
            width: 100%;
            margin-bottom: 8px;
          }
        }
      }

      .cta {
        width: 320px;
        margin: 72px auto 12px;
      }
    }
  }

  .lp-footer {
    background-color: #ba5d72;
    margin-top: -2px;

    .logo {
      padding: 80px 0 120px;
      width: 320px;
      max-width: 80%;
      margin: 0 auto;
      display: block;
    }

    .links {
      display: flex;
      gap: 20px;
      padding-bottom: 20px;
      justify-content: center;
      font-size: 20px;
      font-weight: bold;

      a:hover {
        color: $papaya;
      }
    }
  }
}
