@import '../../palette';
@import 'node_modules/include-media/dist/_include-media.scss';

.__get-coins {
  
  section.pricing {
    padding: 12px;
    max-width: 700px;
    margin: 0 auto;
    
    @include media("<=900px") {
      margin-bottom: 32px;
    }
  }

  section.details {
    background-color: $olive;
    
    > div {
      margin: 0 auto;
      max-width: 700px;
      padding: 12px;
    }
  }

  section.divider {
    display: flex;

    .left-bg {
      background: linear-gradient(to top, $olive 89%, transparent 89%);
      flex: 1;
    }

    .right-bg {
      background: linear-gradient(to top, $olive 19%, transparent 19%);
      flex: 1;
    }
    
    .lines {
      display: flex;
      height: 100px;
      width: 980px;
    }

    .line-left {
      flex: 2;
      background: linear-gradient(22deg, $olive 37%, transparent 37%);
      margin-top: -52px;
    }
    
    .line-middle {
      flex: 5;
      background: linear-gradient(-6deg, $olive 37%, transparent 37%);
    }
    
    .line-right {
      flex: 2;
      background: linear-gradient(10deg, $olive 37%, transparent 37%);
      margin-top: -18px;
    }

    @include media("<=980px") {
      height: 60px;
      
      transform: rotate(3deg);
      background-color: $olive;
      margin-bottom: -24px;
      margin-left: -4px;
      margin-top: 32px;

      .lines, .left-bg, .right-bg {
        display: none;
      }
    }
  }

  .header {
    padding-top: 32px;
    background-color: $green;
    z-index: 2;
    font-family: $fredoka;
    margin: 0px auto 40px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.2rem;
    letter-spacing: 1px;
    text-align: center;

    > svg {
      fill: $orange;
      margin-right: 8px;

      @include media("<=720px") {
        display: none;
      }
    }
  }

  table.coin-tiers {
    width: 100%;
    font-family: $roboto;
    text-align: center;

    th, td {
      padding: 16px 0;
    }

    tr {
      border-bottom: 1px solid rgba(white, 0.2);
    }

    .right-column {
      color: blue;
    }

    thead {
      .pricing {
        width: 40%;

        > div {
          border-top-left-radius: 20px;
        }
      }

      .right-column {
        > div {
          border-top-right-radius: 20px;
        }
      }

      .top-corner {
        padding: 0;
        vertical-align: bottom;
        
        > div {
          background-color: $dark_green;
          height: 40px;
        }
      }

      .coins {
        width: 38%;
        padding: 32px 0 24px;
        font-size: 18px;
        background-color: $lime;
        color: $purple;
        border-top-left-radius: 48px;
        border-top-right-radius: 48px;
        border-bottom: 1px solid $lime;
      }

      @include media("<=720px") {
        .pricing {
          width: 34%;
        }

        .coins {
          width: 62%;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid rgba(white, 0.2);

        &.content {
          background-color: $dark_green;
        }

        .pricing {
          font-size: 20px;
          font-weight: bold;

          &.with-sale {
            font-size: 24px;
          }
          
          p {
            margin: 0;
          }

          del {
            font-weight: normal;
            font-size: 18px;
          }

          .sale {
            background-color: $yellow;
            color: $dark_green;
            border-radius: 4px;
            padding: 4px;
            font-size: 11px;
            vertical-align: middle;
            text-transform: uppercase;
          }
        }

        .coins {
          background-color: $lime;

          &:not(.bottom) {
            border-bottom: 1px solid $lime;
          }

          .purchase-button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 32px;
            width: 150px;
            height: 50px;
            font-weight: 550;
            font-size: 20px;
            background-color: $purple;
            border: none;
      
            &:hover, &:active {
              background-color: $light_purple;
            }
      
            svg {
              margin-right: 6px;
            }
          }

          .song-price {
            margin: 8px 20px 4px;
            color: $green;
            font-size: 14px;
            font-style: italic;
          }
        }

        &:nth-last-child(2) {
          border-bottom: none;
        }

        &:last-of-type {
          border-bottom: none;

          .coins {
            border-bottom-left-radius: 48px;
            border-bottom-right-radius: 48px;
            height: 68px;
          }
        }

        td {
          &.bottom-corner {
            vertical-align: top;
            padding: 0;

            &-left {
              > div {
                border-bottom-left-radius: 16px;
              }
            }

            &-right {
              > div {
                border-bottom-right-radius: 16px;
              }              
            }
  
            > div {
              height: 30px;
              background-color: $dark_green;
            }
          }
        }
      }
    }
  }

  .summary {
    margin-top: 60px;
    display: flex;
    align-items: center;
    gap: 20px;

    @include media("<=720px") {
      flex-direction: column;
    }

    a {
      color: $yellow;
    }

    .summary-coin {

      > svg {
        width: 180px;
        height: 180px;

        @include media("<=720px") {
          width: 72px;
          height: 72px;
        }
      }
    }

    h3 {
      font-size: 3.2rem;
      line-height: 4.8rem;

      @include media("<=720px") {
        font-size: 2rem;
        line-height: 2rem;
        text-align: center;
      }
    }
  }

  h2 {
    margin: 32px 0 48px;
    font-size: 1.5rem;
    font-weight: bold;
  }

  h5 {
    font-style: italic;
    font-weight: normal;

    &.new-ways {
      margin: 48px 0;
    }
  }
  
  .reward {
    display: flex;
    margin-bottom: 24px;
  
    strong {
      color: $yellow;
    }

    .coin-amount {
      margin-inline-end: 40px;

      .amount {
        display: flex;
        align-items: center;
        font-family: $roboto;
        gap: 6px;
        background-color: $orange;
        color: $white;
        padding: 4px 12px;
        border-radius: 8px;
        font-size: 26px;
        font-weight: bold;
        min-width: 100px;
        align-items: center;
        
        .animated-container {
          position: relative;
          flex: 1;
          overflow: hidden;

          .invisible-amount {
            visibility: hidden;
          }

          .animated-amount {
            position: absolute;
            top: 0;
            text-align: center;
            display: block;
            left: 50%;
            transform: translate(-50%, -108px);
            line-height: 36px;
            transition: 0.3s;

            @include media("<=720px") {
              margin-top: -6px;
            }
          }
        }

        > span {
          display: block;
          text-align: center;
          flex: 1;
        }

        &[role=button] {
          &:hover {
            background-color: $light_orange;
            transition: 0.3s;
          }
        }
      }

      @include media("<=720px") {
        margin-inline-end: 16px;

        .amount {
          padding: 4px 8px;
          font-size: 16px;
          min-width: 80px;
        }
      }
    }

    h3 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0 0 4px;
      
      &[role=button] {

        &:hover {
          color: $yellow;
          text-decoration: underline;
        }
      }
    }

    .link-copied {
      color: $lime;
      text-align: center;
      opacity: 1;
      transition: 0.3s all;
      margin-top: 5px;

      &.hidden {
        visibility: hidden;
        opacity: 0;
        transform: translateY(5px);
        transition: 0.3s all;
      }
    }

    input {
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
    }

    .display-name-button {
      background-color: $red;
      color: $white;
      font-weight: bold;
      border-radius: 32px;
      border: none;
  
      &:hover:not(:disabled) {
        background-color: $light_red;
      }
  
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba($purple, 0.5);
      }
      
      &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
        background-color: $light_red;
        box-shadow: 0 0 0 0.2rem rgba($purple, 0.5);
      }
    }

    .copy-link {
      margin-top: 24px;
      margin-bottom: 12px;

      .btn-primary {
        background-color: $red;
        color: $white;
        font-weight: bold;
        border-top-right-radius: 32px;
        border-bottom-right-radius: 32px;
        padding-right: 16px;
        border: none;
    
        &:hover:not(:disabled) {
          background-color: $light_red;
        }
    
        &:focus {
          box-shadow: 0 0 0 0.2rem rgba($purple, 0.5);
        }
        
        &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active {
          background-color: $light_red;
          box-shadow: 0 0 0 0.2rem rgba($purple, 0.5);
        }
      }
    }
  }
}

.__animay-sale {

  padding-top: 32px;
  width: 1080px;
  max-width: 90%;
  margin: 0px auto;

  &-overflow-container {
    overflow: hidden;
  }

  .content {
    margin: 12px auto;

    background-color: $dark_green;
    border-radius: 48px;
    padding: 12px 0px;
    position: relative;
  
    max-width: 100%;
    text-align: center;

    @include media("<=768px") {
      border-radius: 32px;
      padding-top: 80px;
    }

    .sale-line {
      position: relative;
      background-color: white;
      width: 80%;
      margin-bottom: 80px;
    
      .sale-banner {
        position: relative;
        text-align: left;
    
        .sale-title {
          font-family: $bangers;
          font-size: 160px;
          line-height: 156px;
          text-stroke: 1px black;
          -webkit-text-stroke: 1px black;
          color: $yellow;
          z-index: 2;
          position: relative;
          text-align: center;

          @include media("<=1080px") {
            font-size: 120px;
          }

          @include media("<=720px") {
            font-size: 13vw;
            line-height: 86px;
          }
    
          &.title-shadow {
            position: absolute;
            width: 100%;
            z-index: 1;
            color: $red;

            top: 3%;
            transform: translateX(-50%);
            left: 50.5%;
          }
        }
      }
    }

    h3 {
      font-size: 44px;
      color: $lime;
      margin-bottom: 60px;
      font-family: $fredoka;
      font-weight: normal;

      @include media("<=720px") {
        width: 80%;
        font-size: 28px;
        margin-top: 48px;
        margin-bottom: 80px;
      }
    }

    .sale-footer {
      margin: 0 28px 16px auto;
      width: 400px;
      text-align: left;
      font-style: italic;

      @include media("<=720px") {
        text-align: center;
        max-width: 80%;
        font-size: 16px;
        margin: 0 auto 8px;
      }
    }

    .art {

      &-star-container {
        display: block;
        position: absolute;
        transform: rotate(-10deg) translate(-25%, -22%);
        width: 280px;
        text-align: center;
        font-size: 22px;
        line-height: 28px;
        font-weight: bold;

        &:hover {
          filter: brightness(1.05);

          .star-text {
            transform: translate(-50%, -55%);
            transition: 0.3s transform;
          }
          .star-top {
            transform: translateY(-5px);
            transition: 0.3s transform;
          }
        }

        @include media("<=1080px") {
          width: 240px;
          transform: rotate(-10deg) translate(-30%, -22%);
        }

        @include media("<=768px") {
          width: 180px;
          font-size: 16px;
          line-height: 22px;
          transform: rotate(-10deg) translate(-20%, -84%);
        }

        .star-text {
          position: absolute;
          width: 160px;
          color: $red;
          z-index: 10;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          @include media("<=768px") {
            width: 120px;
          }
        }

        > svg {
          width: 100%;
          height: 100%;
        }

        .star-top {
          fill: $yellow;
          z-index: 2;
          position: relative;
        }

        .star-shadow {
          position: absolute;
          top: 5%;
          left: 2%;
          fill: $red;
          z-index: 1;
        }
      }

      &-avatar {
        position: absolute;
        top: -285px;
        width: 350px;
        left: 100%;
        transform: translateX(-54px);

        @include media("<=720px") {
          top: -165px;
          width: 200px;
          left: 100%;
          transform: translateX(-64px);      
        }
      }
    }

    .__countdown-timer {
      margin: 32px 24px;
      justify-content: center;

      .time-unit-container {
        margin: 0 12px;

        .time-amount {
          font-size: 72px;
          line-height: 64px;
          font-family: $fredoka;

          @include media("<=720px") {
            font-size: 48px;
            line-height: 52px;
          }
        }

        .time-unit {
          font-size: 14px;
        }
      }
    }
  }
}

.__summer_sale-countdown {
  padding-top: 32px;
  width: 1080px;
  max-width: 90%;
  margin: 0px auto;

  .content {
    margin: 12px auto;

    background-color: $dark_green;
    border-radius: 48px;
    padding: 72px 8px;
    position: relative;
  
    max-width: 100%;
    text-align: center;

    @include media("<=720px") {
      border-radius: 32px;
      padding: 60px 8px;
    }

    h3 {
      font-size: 40px;
      color: $yellow;
      margin-bottom: 12px;
      font-family: $fredoka;
      font-weight: normal;

      @include media("<=720px") {
        font-size: 28px;
      }
    }

    h2 {
      font-family: $dancing_script;
      color: $orange;
      font-size: 6rem;
      text-shadow: 0 4px 0 $red;
      margin: 0 auto 20px;
      width: 208px;

      @include media("<=720px") {
        font-size: 4rem;
        text-shadow: 0 3px 0 $red;
      }
    }

    .art {
      &-sun {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(20%, -10%);

        @include media("<=720px") {
          transform-origin: top right;
          transform: scale(0.4) translate(15%, -30%);
        }
      }

      &-ice-cream {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(-20%, 10%);

        @include media("<=720px") {
          transform-origin: bottom left;
          transform: scale(0.4) translate(-20%, 12%);
        }
      }
    }

    .__countdown-timer {
      margin: 24px;
      justify-content: center;

      .time-unit-container {
        margin: 0 12px;

        .time-amount {
          font-size: 72px;
          line-height: 64px;
          font-family: $fredoka;

          @include media("<=720px") {
            font-size: 48px;
            line-height: 52px;
          }
        }

        .time-unit {
          font-size: 14px;
        }
      }
    }
  }
}

.__winter-sale-countdown {

  &-container {
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 20px;
  }

  padding-top: 32px;
  width: 1080px;
  max-width: 95%;
  margin: 0px auto;

  .content {
    margin: 12px auto;

    background-color: $dark_green;
    border-radius: 48px;
    padding: 40px 48px;
    position: relative;
  
    max-width: 100%;

    @include media("<=900px") {
      padding: 20px 20px;
    }

    @include media("<=720px") {
      border-radius: 32px;
      text-align: center;
      padding: 20px 20px 160px;
      margin-bottom: 40px;
    }

    h3 {
      font-size: 40px;
      color: $yellow;
      margin-bottom: 12px;
      font-family: $fredoka;
      font-weight: normal;
      margin-left: 12px;

      @include media("<=720px") {
        font-size: 28px;
      }
    }

    h2 {
      font-family: $dancing_script;
      color: $white;
      font-size: 6rem;
      text-shadow: 0 4px 0 $olive;
      margin: 0 auto 20px;

      @include media("<=900px") {
        font-size: 4rem;
        text-shadow: 0 3px 0 $olive;
      }
    }
    
    p {
      margin-bottom: 8px;
    }

    .art-winter-snow {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: scale(0.9) translate(10%, 5%);
      transform-origin: bottom right;

      @include media("<=920px") {
        transform: scale(0.7) translate(10%, 5%);
      }

      @include media("<=800px") {
        transform: scale(0.5) translate(10%, 5%);
      }

      @include media("<=720px") {
        transform: scale(0.4) translate(15%, 25%);
      }
    }

    .__countdown-timer {
      margin: 24px 0;
      color: $white;

      @include media("<=720px") {
        justify-content: center;
      }

      .time-unit-container {
        margin: 0 12px;
        text-align: center;

        .time-amount {
          font-size: 72px;
          line-height: 64px;
          font-family: $fredoka;

          @include media("<=720px") {
            font-size: 48px;
            line-height: 52px;
          }
        }

        .time-unit {
          font-size: 14px;
        }
      }
    }
  }
}

section.__get-coins-faq {
  max-width: 700px;
  padding: 12px;
  margin: 20px auto;

  .question {
    h3 {
      margin: 40px 0 20px;
      text-transform: uppercase;
      font-weight: 500;
      color: $yellow;
      font-size: 1.6rem;

      @include media("<=720px") {
        font-size: 1.3rem;
      }
    }

    p {
      font-size: 1.2rem;
      line-height: 2rem;

      @include media("<=720px") {
        font-size: 1.1rem;
        line-height: 1.8rem;
      }
    }
  }

  a.get-coins {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    font-weight: 700;
    font-size: 22px;
    background-color: $orange;
    border: none;
    padding: 12px 20px;
    border: 1px solid rgba(white, 0.5);
    margin: 20px auto;

    &:hover, &:active {
      background-color: $light_orange;
      color: $white;
      text-decoration: none;
      transition: 0.3s;
    }

    svg {
      margin-right: 6px;
    }
  }
}
