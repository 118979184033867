@import '../../palette';

.__editor-equalizer {
  padding: 2px;
  margin-bottom: 12px;

  width: 120px;
  height: 32px;

  display: flex;
  align-items: baseline;
  justify-content: space-around;
  margin-left: auto;

  .bar {
    display: block;
    width: 3px;
    background-color: $yellow;
    height: 32px;
    border-radius: 4px;
    animation: animation-editor-equalize 8s 0s infinite paused;

    &.playing {
      animation-play-state: running;
    }
  }
}

// Inspiration: https://codepen.io/gauldo/pen/MdOoOZ
@keyframes animation-editor-equalize {
  0% {
    height: 32px;
  }
  4% {
    height: 28px;
  }
  8% {
    height: 24px;
  }
  12% {
    height: 12px;
  }
  16% {
    height: 4px;
  }
  20% {
    height: 12px;
  }
  24% {
    height: 24px;
  }
  28% {
    height: 10px;
  }
  32% {
    height: 24px;
  }
  36% {
    height: 32px;
  }
  40% {
    height: 4px;
  }
  44% {
    height: 24px;
  }
  48% {
    height: 32px;
  }
  52% {
    height: 12px;
  }
  56% {
    height: 10px;
  }
  60% {
    height: 12px;
  }
  64% {
    height: 32px;
  }
  68% {
    height: 12px;
  }
  72% {
    height: 32px;
  }
  76% {
    height: 4px;
  }
  80% {
    height: 32px;
  }
  84% {
    height: 18px;
  }
  88% {
    height: 32px;
  }
  92% {
    height: 4px;
  }
  96% {
    height: 32px;
  }
  100% {
    height: 32px;
  }
}